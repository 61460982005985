/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/10/2024
 */

import React, { useMemo } from "react";
import WebsiteMissingWarning from "../../WebsiteMissingWarning";
import WebsitesVerifiedInfo from "../../WebsiteVerifiedInfo";
import InstallAccordion from "../../InstallAccordion";
import ClipboardCopyInput from "../../../../component/ClipboardCopyInput";
import FeatureFlag from "../../../../component/FeatureFlag";
import {
  getInstallDivTag,
  getInstallIframeTag,
  installScripTag,
} from "../../../../templates/WidgetPageTemplate/InstallTab/helpers";
import appConfig from "../../../../../../appConfig";

const SquareSpaceInstructions = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onGoToAddWebsite,
}) => {
  const installDiv = useMemo(
    () => getInstallDivTag(widgetType, uuid),
    [widgetType, uuid]
  );
  return (
    <div>
      <FeatureFlag name={"squarespace-embeddable"}>
        <div className={"w-100 d-flex align-items-center flex-column"}>
          <p className={"text-center"}>
            Watch our tutorial video below or{" "}
            <a
              target={"_blankWordpressInstructions"}
              href={
                "https://help.display.church/en/articles/6750477-squarespace-embedding-your-view"
              }
            >
              Read our Article
            </a>
            .
          </p>
          <div className={"install-modal--video-tutorial"}>
            <div>
              <iframe
                src="https://player.vimeo.com/video/773599015?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                title="Embedding into Wordpress"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </FeatureFlag>

      <div className={"my-4"}>
        <WebsiteMissingWarning onAddWebsite={onGoToAddWebsite} />
        <WebsitesVerifiedInfo />
      </div>
      <InstallAccordion
        title={`Installing ${appConfig.displayName} on SquareSpace`}
      >
        <div className={"alert alert-info"}>
          If you are using an older version of SquareSpace, the path to your
          Code Injection might look a little different.{" "}
        </div>
        <ol>
          <li>
            In SquareSpace, click on <strong>Settings</strong>.
          </li>
          <li>
            Within the Settings menu, click on <strong>Advanced</strong>.
          </li>

          <li>
            Within the Advanced Settings menu, click on{" "}
            <strong>Code Injection</strong>.
          </li>
          <li>
            Copy and Paste the following snippet into the{" "}
            <strong>HEADER</strong> section.
            <ClipboardCopyInput
              id={"embeddable_script"}
              value={installScripTag}
              placeholder={`${widgetTypePrintable} embeddable script`}
            />
          </li>
          <li>
            Click <strong>Save</strong> to confirm your changes.
          </li>
        </ol>
        <p>
          <strong>REMEMBER!</strong> You only have to do the above part once,
          regardless of the number of widgets you embed. In other words, if you
          embed more than one {widgetTypePrintable} or group in this same
          website, you do not need to repeat this operation.
        </p>
      </InstallAccordion>
      <InstallAccordion
        title={`Adding ${widgetTypePrintable} to selected page`}
      >
        <ol>
          <li>
            Edit the page on which you want to display the {widgetTypePrintable}
            .
          </li>
          <li>
            Click on Add Block and choose <strong>Code</strong> block option.
          </li>
          <li>
            Position the block where you want you calendar or groups widget to
            display.
          </li>
          <li>
            Double click on the block to open the content popup. This is where
            you will tell SquareSpace more about the {widgetTypePrintable} view
            you wish to embed.
          </li>
          <li>
            Select <strong>HTML</strong> mode.
          </li>
          <li>
            Copy the following code snippet and paste it into the popup content
            space.
            <ClipboardCopyInput
              id={"embeddable_div"}
              value={installDiv}
              placeholder={`${widgetTypePrintable} embeddable div`}
            />
          </li>
        </ol>
      </InstallAccordion>
    </div>
  );
};

export default SquareSpaceInstructions;
