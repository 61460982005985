/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2024
 */

import React from "react";
import WizardStep from "./WizardStep";

const Wizard = ({ currentStep = 1 }) => {
  return (
    <div className={`install-modal--header-progress step step${currentStep}`}>
      <WizardStep done={currentStep >= 1} />
      <WizardStep done={currentStep >= 2} />
      <WizardStep done={currentStep >= 3} />
    </div>
  );
};

export default Wizard;
