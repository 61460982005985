/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */
import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import DesignTooltip from "./DesignTooltip";
import CheckPropertyDependency from "../../CheckPropertyDependency";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const DesignSetup = (props) => {
  const { title, name } = props;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const settings = props.settings || {};

  /**
   *
   */
  const onInputValueChange = useCallback(
    (e) => {
      props.updateDesignProp([
        { field: props.name + "Option", value: "custom" },
        { field: props.name, value: e.target.value },
      ]);
    },
    [props.updateDesignProp]
  );

  if (!props.show) {
    return null;
  }

  const value = widgetSettingsContext[name];

  return (
    <div className={"design-element"}>
      <label className="option-label">
        <span className={"option-title"}>{title}</span>
        <DesignTooltip help={props.tooltip} />
      </label>
      <div className="material-switch">
        <select
          id={name}
          className="form-control"
          value={value}
          onChange={onInputValueChange}
        >
          {props.control.options.dropdownOptions.map((i, idx) => {
            return (
              <option key={"dbtd-" + name + idx} value={i.value}>
                {i.label}
              </option>
            );
          })}
        </select>
        <CheckPropertyDependency
          settings={settings}
          dependsOnName={props.dependency && props.dependency.name}
          dependsOnValue={props.dependency && props.dependency.value}
          dependsOnDefaultValue={
            props.dependency && props.dependency.defaultValue
          }
          flashMessage={props.dependency && props.dependency.message}
          showDependency={props.showDependencyWarning}
        />
      </div>

      <div className="clearfix"></div>
    </div>
  );
};

DesignSetup.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["dropdown"]).isRequired,
    options: PropTypes.shape({
      dropdownOptions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    }),
  }).isRequired,
};

DesignSetup.defaultProps = {
  show: true,
};

export default DesignSetup;
