/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/10/2023
 */
import AxiosConfig from "../../../../../AxiosConfig";
import appConfig from "../../../../../appConfig";

export const widgetAddress = window.__DCE__DEV__
  ? `${window.location.origin}/${appConfig.widgetPath}`
  : `https://${process.env.WIDGETS_DOMAINNAME}/${appConfig.widgetPath}`;
export const loaderScriptUrl = window.__DCE__DEV__
  ? `${widgetAddress}/loader.js`
  : `${widgetAddress}/loader.min.js`;

export const eventAlertsScriptUrl = window.__DCE__DEV__
  ? `${widgetAddress}/alerts.js`
  : `${widgetAddress}/alerts.min.js`;

export const installScripTag = `<script async id="dce-embeddable-script" src="${loaderScriptUrl}"></script>`;

export const installAlertsScripTag = `<script async id="dc-alerts-script" src="${eventAlertsScriptUrl}"></script>`;

export const getInstallDivTag = (widgetType, uuid) =>
  `<div class="dce-${widgetType}" id="${uuid}"></div>`;

export const getInstallIframeTag = (widgetType, uuid, slug) =>
  `<div class="dce-${widgetType}" id="${uuid}" iframe="true" slug="${slug}"></div>`;

export const wixHeadScript = `<script async id="dce-embeddable-script" src="${loaderScriptUrl}" wix="true"></script>`;

export const getWixEmbeddedScript = (widgetType, uuid) => {
  const queryParams = {
    min: window.__DCE__DEV__ ? false : true,
    uuid,
    widget_type: widgetType,
  };

  const wixLoaderScript =
    `${widgetAddress}/wix_loader.js?` +
    AxiosConfig.objectToURLQuery(queryParams);

  return `<div class="dce-${widgetType}" id="${uuid}"></div>
<script>
  var script = document.createElement("script");
  script.src = "${wixLoaderScript}";
  script.async = true;
  script.id = 'dc-wix-embeddable-script';
  document.body.appendChild(script);
</script>`;
};
