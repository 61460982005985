/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/10/2024
 */

import React, { useMemo } from "react";
import WebsiteMissingWarning from "../../WebsiteMissingWarning";
import WebsitesVerifiedInfo from "../../WebsiteVerifiedInfo";
import InstallAccordion from "../../InstallAccordion";
import ClipboardCopyInput from "../../../../component/ClipboardCopyInput";
import FeatureFlag from "../../../../component/FeatureFlag";
import {
  getInstallDivTag,
  installAlertsScripTag,
} from "../../../../templates/WidgetPageTemplate/InstallTab/helpers";
import OneTimerAlert from "./OneTimerAlert";

const WordPressInstructions = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onGoToAddWebsite,
}) => {
  const installDiv = useMemo(
    () => getInstallDivTag(widgetType, uuid),
    [widgetType, uuid]
  );
  return (
    <div>
      {/*<FeatureFlag name={"wordpress-embeddable"}>
        <div className={"w-100 d-flex align-items-center flex-column"}>
          <p className={"text-center"}>
            Watch our tutorial video below or{" "}
            <a
              target={"_blankWordpressInstructions"}
              href={
                "https://help.display.church/en/articles/6649262-wordpress-embedding-your-view"
              }
            >
              Read our Article
            </a>
            .
          </p>
          <div className={"install-modal--video-tutorial"}>
            <div>
              <iframe
                src="https://player.vimeo.com/video/763618925?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                title="Embedding into Wordpress"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </FeatureFlag>
*/}
      <div className={"my-4"}>
        <WebsiteMissingWarning onAddWebsite={onGoToAddWebsite} />
        <WebsitesVerifiedInfo />
      </div>
      <InstallAccordion
        title={"Recommended installation"}
        defaultExpanded={true}
        expandable={false}
      >
        <OneTimerAlert />
        <p>
          Copy and Paste the following Snippet into the{" "}
          <span className={"text-purple"}>&lt;head&gt;</span> of your website.
          You can use any WP Plugin to do this.
        </p>
        <ClipboardCopyInput
          id={"embeddable_script"}
          value={installAlertsScripTag}
          placeholder={`${widgetTypePrintable} embeddable script`}
        />
      </InstallAccordion>
    </div>
  );
};

export default WordPressInstructions;
