/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/10/2024
 */

import React, { useCallback, useState } from "react";
import ClipboardCopyInput from "../ClipboardCopyInput";
import RadioInput from "./RadioInput";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

const ShareableUrl = ({
  url,
  widgetModel,
  widgetTypeName,
  onUpdateSettings,
  onSubmit,
}) => {
  const [hasRedirection, setHasRedirection] = useState(
    widgetModel?.widget_settings?.hasRedirection || false
  );

  const [redirectUrl, setRedirectUrl] = useState(
    widgetModel?.widget_settings?.redirectionUrl || ""
  );

  useDidMountEffect(() => {
    onSubmit();
  }, [hasRedirection]);

  /**
   *
   * @type {(function(*))|*}
   */
  const onToggleUrlRedirection = useCallback(
    (fieldName) => (e) => {
      setHasRedirection(!hasRedirection);
      onUpdateSettings("hasRedirection", !hasRedirection);
    },
    [widgetModel, hasRedirection, onUpdateSettings]
  );

  /**
   *
   * @type {(function(*))|*}
   */
  const onChangeRedirectUrl = useCallback(
    (e) => {
      setRedirectUrl(e.target.value);
      onUpdateSettings("redirectionUrl", e.target.value);
    },
    [redirectUrl, onUpdateSettings]
  );

  const onSubmitRedirectUrl = useCallback(() => {
    onSubmit();
  }, [redirectUrl]);

  const redirectionBlock = hasRedirection ? (
    <div className={"input-group"}>
      <input
        id={"url-redirect"}
        type="text"
        className="form-control form-control-sm"
        placeholder={"Webpage URL address"}
        value={redirectUrl}
        onChange={onChangeRedirectUrl}
      />
      <div className={"input-group-append"}>
        <button className={"btn btn-primary"} onClick={onSubmitRedirectUrl}>
          Save
        </button>
      </div>
    </div>
  ) : null;

  return (
    <form action="#" className={"m-t-15"}>
      <h5>Sharable URL</h5>
      <div className="form-group ">
        <div className="col-xs-12">
          <ClipboardCopyInput
            id={"shareable_url"}
            value={url}
            placeholder={`${widgetTypeName} Shareable URL`}
          />
          <div className={"alert alert-link"}>
            <div className={"d-flex align-items-start"}>
              <i className={"material-icons icon-sm text-info p-0 m-0 m-r-5"}>
                info
              </i>
              <small>
                Copy the Link above to easily share it via email, social, mobile
                app or or any instant messaging app.
              </small>
            </div>
          </div>
        </div>
      </div>
      <h5>
        Link Redirection <div className={"badge badge-success"}>BETA</div>
      </h5>
      <div className="col-xs-12">
        <div className="form-group ">
          <RadioInput
            fieldName={"hasRedirection"}
            label={"Enable URL redirection"}
            onChange={onToggleUrlRedirection}
            checked={hasRedirection}
            disabled={false}
            className={"mb-0"}
            variant={"warning"}
          />
          <div className={"alert alert-link"}>
            <div className={"d-flex align-items-start"}>
              <i className={"material-icons icon-sm text-info p-0 m-0 m-r-5"}>
                info
              </i>
              <small>
                Redirect users to the website where your widget is embedded,
                enhancing your site's page views and engagement.
              </small>
            </div>
          </div>
          {redirectionBlock}
        </div>
      </div>
    </form>
  );
};

export default ShareableUrl;
