/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2024
 */

import React, { useCallback, useState } from "react";
import classNames from "classnames";

const InstallAccordion = ({
  title,
  children,
  defaultExpanded = false,
  expandable = true,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onHeaderClick = useCallback(
    () => {
      if (!expandable) return;
      setExpanded(!expanded);
    },
    [expanded],
    expandable
  );

  const expandableIcon = expandable ? (
    <div className={"install-modal--accordion-header-collapse"}>
      <i className={"material-icons"}>chevron_left</i>
    </div>
  ) : null;

  return (
    <div
      className={classNames("install-modal--accordion", {
        expanded,
      })}
    >
      <div>
        <div
          className={classNames("install-modal--accordion-header ", {
            "cursor-pointer": expandable,
          })}
          onClick={onHeaderClick}
        >
          <div className={"install-modal--accordion-header-title"}>
            <h5>{title}</h5>
          </div>
          {expandableIcon}
        </div>
        <div className={"install-modal--accordion-body"}>
          <div className={"install-modal--accordion-body-content"}>
            {children}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default InstallAccordion;
