/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2024
 */

import React from "react";
import InstallAccordion from "./../../InstallAccordion";
import ClipboardCopyInput from "../../../../component/ClipboardCopyInput";
import WebsiteMissingWarning from "./../../WebsiteMissingWarning";
import WebsitesVerifiedInfo from "./../../WebsiteVerifiedInfo";
import { installAlertsScripTag } from "../../../../templates/WidgetPageTemplate/InstallTab/helpers";
import OneTimerAlert from "./OneTimerAlert";

const StandardInstall = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onGoToAddWebsite,
}) => {
  return (
    <div>
      {/*<div className={"w-100 d-flex align-items-center flex-column"}>
        <p className={"text-center"}>
          Watch our tutorial video below or <a href={"#"}>Read our Article</a>.
        </p>
        <div>
          <iframe
            width="355"
            height="200"
            src="https://www.youtube.com/embed/7c7Wfz5rMn0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>*/}

      <div className={"my-4"}>
        <WebsiteMissingWarning onAddWebsite={onGoToAddWebsite} />
        <WebsitesVerifiedInfo />
      </div>
      <InstallAccordion
        title={"Recommended installation"}
        defaultExpanded={true}
        expandable={false}
      >
        <OneTimerAlert />

        <p>
          Copy and Paste this Snippet into the{" "}
          <span className={"text-purple"}>&lt;head&gt;</span> of your website:
        </p>
        <ClipboardCopyInput
          id={"embeddable_script"}
          value={installAlertsScripTag}
          placeholder={"Calendar embeddable script"}
        />
      </InstallAccordion>
    </div>
  );
};

export default StandardInstall;
