/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/10/2024
 */

import React, { useCallback } from "react";
import classNames from "classnames";

const PlatformSelectorButton = ({ id, icon, label, selected, onSelect }) => {
  /**
   *
   */
  const onSelectedClick = useCallback(() => {
    onSelect(id);
  }, [id, onSelect]);

  return (
    <div
      className={classNames("install-modal--platform-button", { selected })}
      onClick={onSelectedClick}
    >
      <img
        src={`/img/install_icons/${icon}`}
        alt={`${label} icon`}
        title={label}
      />
      <span>{label}</span>
    </div>
  );
};

export default PlatformSelectorButton;
