/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/10/2024
 */

import React from "react";
import { useDomainsStore } from "../../../../hooks/redux/domains";

const WebsiteMissingWarning = ({ onAddWebsite = () => {} }) => {
  const { domains } = useDomainsStore();

  if (domains.collection.length > 0) {
    return null;
  }

  return (
    <div className={""}>
      <div className={"d-flex align-items-center gap-xxs"}>
        <i className={"material-icons icon-sm text-danger"}>error</i>
        <span>
          You have no verified websites added yet.{" "}
          <span
            className={"text-primary cursor-pointer anchor"}
            onClick={onAddWebsite}
          >
            Add one now
          </span>
          .
        </span>
      </div>
    </div>
  );
};

export default WebsiteMissingWarning;
