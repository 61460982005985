/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2024
 */

import React from "react";
import classNames from "classnames";

const WizardStep = ({ done = false }) => {
  return (
    <div
      className={classNames("install-modal--header-progress-step", {
        filled: done,
      })}
    ></div>
  );
};

export default WizardStep;
