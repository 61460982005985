/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

import React from "react";
import WidgetPageTemplate from "../templates/WidgetPageTemplate";
import { WT } from "../consts/widget";
import { IT } from "../../../consts";
import { getStrategy } from "../../signups/strategies/IntegrationContext";
import WidgetContext from "../contexts/WidgetContext";
import {
  getShareableUrl,
  indexPageUrl,
  signupSettingsValidator,
} from "../../signups/tools";
import Signup from "../../signups/components/Signup";
import { useRouteMatch } from "react-router";
import IntegrationScopeIssueNotification from "../component/IntegrationScopeIssueNotification";
import { useWidgetStore } from "../../../hooks/redux/widget";
import SortSetup from "./SignupPage/SortSetup";

window.__DCE.signup = window.__DCE.signup || {};

const signupWidgetContextValue = {
  allowedIntegrations: [IT.pco],
  getTheLowestPlanMeetingWidgetCriteria: () => {},
  widgetFeaturesCheck: () => [],
  widgetSettingsValidator: signupSettingsValidator,
  getShareableUrl,
  indexPageUrl,
  widgetDisplayName: "Sign-up Forms",
  integrationStrategySelector: getStrategy,
  setupTabComponents: [SortSetup],
};

const IntegrationScopes = {
  [IT.pco]: ["signups"],
  [IT.ccb]: ["forms"],
};

const SignupPage = (props) => {
  const match = useRouteMatch();
  const uuid = match.params.uuid;
  const { widget } = useWidgetStore();

  const syncScopeTurnedOffInfo =
    widget.model && widget.model.integration ? (
      <IntegrationScopeIssueNotification
        integration_uuid={widget.model.integration.uuid}
        mandatoryScopes={IntegrationScopes[widget.model.integration.type] || []}
      />
    ) : null;

  return (
    <WidgetContext.Provider value={signupWidgetContextValue}>
      <WidgetPageTemplate
        widgetType={WT.signups}
        widgetTypeIcon={"edit_note"}
        widgetTypeTitle={"Sign-up Forms"}
      >
        <Signup widgetUUID={uuid} embedded={false} />

        {syncScopeTurnedOffInfo}
      </WidgetPageTemplate>
    </WidgetContext.Provider>
  );
};

export default SignupPage;
