/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/10/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { useDomainsStore } from "../../../../hooks/redux/domains";

const WebsitesVerifiedInfo = ({ onAddWebsite = () => {} }) => {
  const { domains } = useDomainsStore();

  if (!domains.collection.length) {
    return null;
  }

  return (
    <>
      <div className={"w-100"}>
        <div className={"d-flex align-items-center gap-xxs"}>
          <i className={"material-icons icon-sm text-success"}>check_circle</i>
          <span>
            You have already added verified websites.{" "}
            <a
              href={"/domains"}
              className={"text-primary cursor-pointer anchor"}
              target={"_blankDomains"}
            >
              View them here{" "}
              <i className={"material-icons icon-xs"}>open_in_new</i>
            </a>
            .
          </span>
        </div>
      </div>
    </>
  );
};

export default WebsitesVerifiedInfo;
