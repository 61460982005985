/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React, { useCallback, useContext, useEffect, useMemo } from "react";

import SidebarTab from "../../component/WidgetSettings/SidebarTab";
import MandatoryInformation from "./SettingsTabs/MandatoryInformation";
import PropTypes from "prop-types";
import SuccessAlert from "../../component/SuccessAlert";
import ApiError from "../../component/ApiError";
import CloneSettings from "./SettingsTabs/CloneSettings";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import FeaturedEventsFiltering from "./SettingsTabs/FeaturedEventsFiltering";
import { useCalendarStore } from "../../../../hooks/redux/calendar";
import { useFiltersStore } from "../../../../hooks/redux/filters";
import IntegrationContext from "../../../calendar/strategies/IntegrationContext";
import FormError from "../../component/FormError";
import FiltersSection from "./SettingsTabs/FiltersSection";
import classNames from "classnames";
import SEOSettings from "../../templates/WidgetPageTemplate/SettingsTabs/SEOSettings";
import FeatureFlag from "../../component/FeatureFlag";

const SetupTab = (props) => {
  const { calendar } = useCalendarStore();
  const { integrations, hasSetupChanged } = props;

  const integrationStrategy = useContext(IntegrationContext);

  const integrationModel = useMemo(
    () =>
      calendar.model
        ? integrations.find((i) => i.uuid === calendar.model.integration.uuid)
        : {},
    [calendar.model.integration]
  );

  const { fetchIntegrationFilters, clearFilters } = useFiltersStore();

  useEffect(
    () => {
      fetchIntegrationFilters(
        calendar.model.integration.uuid,
        integrationStrategy.availableFilters,
        calendar.model.integration.type
      );

      return () => {
        clearFilters();
      };
    },
    [
      // TODO Confirm if this is the right way to do this. Does it need to re-run when integrationModel changes?
      /*integrationModel, integrationStrategy.availableFilters*/
    ]
  );

  const cloneSettings = (
    <CloneSettings
      overwriteDesignProp={props.overwriteDesignProp}
      overwriteFilterProp={props.overwriteFilterProp}
    />
  );

  const eventsFiltering =
    calendar.model && calendar.model.uuid ? (
      <FiltersSection
        user={props.user}
        calendarModel={props.calendarModel || {}}
        onUpdateField={props.onUpdateField}
        disabled={props.disabled}
        integrationModel={integrationModel}
        formErrors={props.errors}
        onUpdateFilterSettings={props.onUpdateFilterSettings}
      />
    ) : null;

  const featuredFiltering =
    calendar.model && calendar.model.uuid ? (
      <FeaturedEventsFiltering
        widgetModel={props.calendarModel || {}}
        onUpdateField={props.onUpdateField}
        onUpdateFilterSettings={props.onUpdateFilterSettings}
        disabled={props.disabled}
        formErrors={props.errors}
        onUpdateWidgetSettings={props.onUpdateWidgetSettings}
      />
    ) : null;

  const footer = !props.disabled ? (
    <div>
      <SuccessAlert>
        {calendar.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>

      {Object.keys(props.errors).length ? (
        <FormError errors={Object.values(props.errors)} />
      ) : null}
      <ApiError
        error={calendar.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />

      {!calendar.updateSuccess && !calendar.updateError ? (
        <div
          className={classNames("alert d-flex m-0 gap-xxs p-2", {
            "alert-warning": hasSetupChanged,
            "justify-content-end": !hasSetupChanged,
          })}
        >
          {hasSetupChanged ? (
            <div className="text-left">
              Setup changes will appear visible after saving them.
            </div>
          ) : null}

          <button
            disabled={props.disabled}
            type="submit"
            className="btn btn-primary hidden-xs"
            onClick={props.onSubmit}
          >
            Save
          </button>
        </div>
      ) : null}
    </div>
  ) : (
    <LoadingIndicator />
  );

  return (
    <SidebarTab id="setup_tab" show={true} footer={footer}>
      {eventsFiltering}
      {featuredFiltering}
      <SEOSettings
        widgetModel={props.calendarModel || {}}
        disabled={props.disabled}
        onFieldChange={props.onUpdateWidgetSettings}
      />
      <MandatoryInformation
        calendarModel={props.calendarModel || {}}
        integrations={integrations}
        onUpdateField={props.onUpdateField}
        disabled={props.disabled}
        formErrors={props.errors}
      />

      {cloneSettings}
    </SidebarTab>
  );
};

SetupTab.propTypes = {
  // form fields and errors
  calendarModel: PropTypes.object,
  errors: PropTypes.object,

  // for event filters
  calendar: PropTypes.object,

  // for mandatory information and events filtering
  integrations: PropTypes.array,

  // whether diable form
  disabled: PropTypes.bool,

  onSubmit: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateFilterSettings: PropTypes.func.isRequired,
  onUpdateWidgetSettings: PropTypes.func.isRequired,

  // whether to show events filtering/featured events
  isNew: PropTypes.bool,

  // for cloning
  overwriteDesignProp: PropTypes.func,
  overwriteFilterProp: PropTypes.func,

  // to determine user's plan
  user: PropTypes.object,

  // whether setup has changed
  hasSetupChanged: PropTypes.bool,
};

export default SetupTab;
