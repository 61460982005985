/**
 * Created by piotr.pozniak@thebeaverhead.com on 29/04/2019.
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import copyTextToClipboard from "../../../helpers/clipboard";

const ClipboardCopyInput = (props) => {
  const [showCopiedSuccess, setShowCopiedSuccess] = useState(false);

  useEffect(() => {
    if (showCopiedSuccess) {
      setTimeout(() => {
        setShowCopiedSuccess(false);
      }, 3000);
    }
  }, [showCopiedSuccess]);
  const onCopyTextToClipboard = (id) => () => {
    const successful = copyTextToClipboard(id);

    if (successful) {
      setShowCopiedSuccess(true);
    }
  };

  const copiedSuccessNotification = showCopiedSuccess ? (
    <div>
      <br />
      <div
        className="alert alert-success fade show text-center p-2"
        role="alert"
      >
        <strong>Copied!</strong>
      </div>
    </div>
  ) : null;

  const tag =
    props.tag == "textarea" ? (
      <textarea
        id={props.id}
        type="text"
        className="form-control form-control-sm"
        placeholder={props.placeholder}
        value={props.value}
        rows={props.rows}
        readOnly={true}
      />
    ) : (
      <input
        id={props.id}
        type="text"
        className="form-control form-control-sm"
        placeholder={props.placeholder}
        value={props.value}
        readOnly={true}
      />
    );

  return (
    <div>
      <div className="input-group">
        {tag}
        <div className="input-group-append">
          <button
            className="btn btn-primary btn-sm"
            type="button"
            onClick={onCopyTextToClipboard(props.id)}
          >
            Copy
          </button>
        </div>
      </div>
      {copiedSuccessNotification}
    </div>
  );
};

ClipboardCopyInput.defaultProps = {
  tag: "input",
  rows: 3,
};

ClipboardCopyInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  tag: PropTypes.string,
  rows: PropTypes.number,
};

export default ClipboardCopyInput;
