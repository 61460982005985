/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/04/2022.
 */

import { IT } from "../../../consts";
import CCBStrategy from "./CCBStrategy";
import PCOStrategy from "./PCOStrategy";

/**
 *
 * @param integrationType
 * @returns {SignupsIntegrationStrategy}
 */
export const getStrategy = (integrationType) => {
  switch (integrationType) {
    case IT.ccb:
      return new CCBStrategy();
    case IT.pco:
      return new PCOStrategy();
  }
};
