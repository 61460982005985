/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/07/2023.
 */
import classnames from "classnames";
import React, { useState } from "react";
import PropTypes from "prop-types";
import isFQDN from "validator/lib/isFQDN";
import isPort from "validator/lib/isPort";
import { customTrim } from "../../../../helpers/string";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import ApiError from "../../component/ApiError";
import FormError from "../../component/FormError";

function DomainForm({
  isNew,
  domain,
  updateError = null,
  loading = false,
  onSubmit,
  cancelButton = null,
}) {
  const [state, setState] = useState({
    domainError: null,
    subdomainError: null,
    domainErrorDetails: [],
  });

  /**
   * Handles the validation of a subdomain.
   *
   * @param {string} value - The value to be validated as a subdomain.
   * @returns {boolean} - Returns `true` if the value is a valid subdomain (not starting with 'www'), otherwise `false`.
   */
  const validateSubdomain = (value) => {
    const chunks = value.split(".");
    if (chunks[0] === "www") {
      return false;
    }
    return true;
  };

  /**
   * Handles the validation of domain.
   *
   * @param domain
   */
  const validateDomain = (domain) => {
    if (!domain || typeof domain !== "string") {
      return false;
    }
    const domainAr = domain.split(":");
    if (domainAr.length > 2) {
      return false;
    }
    const isDomainValid = isFQDN(domainAr[0]);
    const isPortValid = domainAr[1] !== undefined ? isPort(domainAr[1]) : true;
    return isDomainValid && isPortValid;
  };

  /**
   * Handles the form submission event for a domain form.
   *
   * @param {Event} e - The event object representing the form submission event.
   */

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const domainValue = customTrim(
      document.querySelector("#domain").value.trim(),
      "/"
    );
    const available = !isNew && domain.uuid ? domain.available : 1;
    let domainErrorDetails = [];

    let domainError = null;
    let subdomainError = null;

    if (!domainValue || !domainValue.length) {
      domainError = "Please enter your website domain name.";
    } else if (!validateDomain(domainValue)) {
      domainError = "Please enter a correct website domain name: ";

      const parser = document.createElement("a");
      parser.href = domainValue;

      if (domainValue.match(/^(https?)\:\/\//)) {
        domainErrorDetails.push(
          "Remove the protocol from the domain name (http:// or https://)"
        );
      } else if (domainValue.match(/(\:\d)?(\/).(.+)/)) {
        const path = domainValue.match(/(\:\d)?(\/).(.+)/);
        domainErrorDetails.push(
          "Remove the website path from the domain name (" + path[0] + ")"
        );
      }
    }

    if (!validateSubdomain(domainValue)) {
      subdomainError = true;
    }

    setState({
      domainError,
      subdomainError,
      domainErrorDetails,
    });

    if (!domainError && !subdomainError) {
      const data = {
        ...domain,
        domain: domainValue,
        available,
      };

      onSubmit(data);
    }
  };

  const subdomainError = state.subdomainError ? (
    <div className="alert alert-warning">
      Remove <strong>www</strong> subdomain from the domain name. It will be
      added automatically. Provide only domain or subdomain name.
    </div>
  ) : null;

  const validationErrors = [
    state.domainError,
    state.domainErrorDetails.length
      ? state.domainErrorDetails.join(
          state.domainErrorDetails.length > 1 ? "," : ""
        )
      : [],
  ];

  return (
    <form onSubmit={handleFormSubmit} id={"domain-add"}>
      <div className="form-group">
        <label>
          Website Address<span className="text-danger">*</span>
        </label>
        <div className="integration-address-container">
          <input
            className={classnames("form-control", {
              "is-invalid": state.domainError || state.subdomainError,
            })}
            id="domain"
            type="text"
            placeholder="Website Domain Name (eg. mydomain.com)"
            defaultValue={!isNew ? domain.domain : ""}
          />
        </div>
      </div>
      {subdomainError}
      <ApiError
        error={updateError}
        defaultErrorMessage={"Could not add Api User. Please try again"}
      />
      <FormError errors={[validationErrors.flat()]} />
      <div className="m-t-20 text-center">
        <div className="form-group submit-container">
          {cancelButton}
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </div>
      </div>
      {loading && <LoadingIndicator />}
    </form>
  );
}

DomainForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
  domain: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
    created: PropTypes.number.isRequired,
    modified: PropTypes.number,
    disabled_date: PropTypes.number,
  }),
  updateError: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default DomainForm;
