/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */

import React, { useCallback, useContext } from "react";
import DesignTooltip from "./DesignTooltip";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import PropTypes from "prop-types";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const NumberSetup = (props) => {
  const { title, name } = props;
  const { options } = props.control;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  /**
   *
   * @param field
   * @returns {(function(*): void)|*}
   */
  const onInputValueChange = useCallback(
    (field) => (e) => {
      props.updateDesignProp([
        { field: field + "Option", value: "custom" },
        { field, value: e.target.value },
      ]);
    },
    [props.updateDesignProp]
  );

  if (!props.show) {
    return null;
  }

  const fieldValue = widgetSettingsContext[name];
  const value = props.control.options.renderValue
    ? props.control.options.renderValue(fieldValue)
    : fieldValue;

  return (
    <div className={"design-element"}>
      <label className="option-label">
        <span className={"option-title"}>{title}</span>
        <DesignTooltip help={props.tooltip} />
      </label>

      <input
        disabled={false}
        value={value}
        onChange={onInputValueChange(name)}
        type="number"
        className="form-control"
        placeholder={options.placeholder}
      />
    </div>
  );
};

NumberSetup.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["number"]).isRequired,
    options: PropTypes.shape({
      placeholder: PropTypes.string,
      min: PropTypes.number,
      updateValue: PropTypes.func,
      renderValue: PropTypes.func,
    }),
  }).isRequired,
};

export default NumberSetup;
