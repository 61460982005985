/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/10/2024
 */

import React from "react";

const OneTimerAlert = ({}) => {
  return (
    <div className={"alert alert-info"}>
      <strong>Important!</strong> You do not have to repeat this process for
      other event alerts. Once you have installed the script, all event alerts
      will be displayed on your website.
    </div>
  );
};

export default OneTimerAlert;
