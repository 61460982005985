export const Platforms = {
  wordpress: "wordpress",
  wix: "wix",
  nucleus: "nucleus",
  squarespace: "squarespace",
  churchco: "churchco",
  mailchimp: "mailchimp",
  standard: "standard",
};

export const PlatformsLabels = {
  [Platforms.wordpress]: "WordPress",
  [Platforms.wix]: "Wix",
  [Platforms.nucleus]: "Nucleus",
  [Platforms.squarespace]: "SquareSpace",
  [Platforms.churchco]: "The Church Co",
  [Platforms.mailchimp]: "Mailchimp",
  [Platforms.standard]: "any website",
};
