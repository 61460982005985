/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/06/2022
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ResendActivationInfo from "./ResendActivationInfo";
import SideMenuItem from "./SideMenuItem";
import { useUserStore } from "../../../../hooks/redux/user";
import { useAutologinStore } from "../../../../hooks/redux/autologin";
import UpgradePlanInfo from "./UpgradePlanInfo";
import FailedPayment1Info from "./FailedPayment1Info";
import FailedPayment2Info from "./FailedPayment2Info";
import SyncIssuesInfo from "./SyncIssuesInfo";
import { useAppStore } from "../../../../hooks/redux/app";
import { useTranslation } from "react-i18next";
import appConfig from "../../../../appConfig";
import SideMenuGroup from "./SideMenuGroup";
import BecomeAffiliateInfo from "./BecomeAffiliateInfo";

const widgetMenuOptions = [
  {
    label: "Calendars",
    icon: "event",
    className: "nav-calendar",
    id: "calendar",
    url: "/calendars",
  },
  {
    label: "Groups",
    icon: "group",
    className: "nav-groups",
    id: "groups",
    url: "/groups",
  },
  {
    label: "Event Alerts",
    icon: "campaign",
    className: "nav-alerts",
    id: "alerts",
    url: "/alerts",
  },
  {
    label: "Sign-up Forms",
    icon: "edit_note",
    className: "nav-signup",
    id: "signup",
    url: "/signups",
  },
  {
    label: "Email",
    iconImage: (
      <img
        className={"img-responsive img-mailchimp"}
        alt="Email"
        src={`/img/install_icons/mailchimp.png`}
      />
    ),
    className: "nav-signup",
    id: "email",
    url: "http://help.display.church/en/articles/10068292-mailchimp-email-template-alpha",
    alpha: true,
    target: "_blank-email-mc-info",
  },
].filter((i) => appConfig.widgets.includes(i.id));

const userMenuOptions = [
  {
    label: "Integrations",
    icon: "hub",
    className: "nav-dashboard",
    id: "integrations",
    url: "/integrations",
  },
  {
    label: "Websites",
    icon: "language",
    className: "nav-dashboard",
    id: "domains",
    url: "/domains",
  },
];

const SidebarMenu = (props) => {
  const { user } = useUserStore();
  const { autologin } = useAutologinStore();
  const { app } = useAppStore();
  const { t } = useTranslation();

  const userRole = useMemo(() => {
    return user.model ? user.model.role : "";
  }, [user]);

  const currentYear = new Date().getFullYear();

  const adminOptionsDisable = autologin.loginSuccess;

  const adminOptions = useMemo(
    () => [
      {
        label: t("Organizations"),
        id: "organizations",
        icon: "corporate_fare",
        url: `/${appConfig.links.organizations}`,
        disabled: adminOptionsDisable,
      },
      {
        label: "Users",
        id: "users",
        icon: "group",
        url: "/users",
        disabled: adminOptionsDisable,
      },
      {
        label: "Coupons",
        id: "coupons",
        icon: "local_activity",
        url: "/coupons",
        disabled: adminOptionsDisable,
      },
      {
        label: "EventOn Licenses",
        id: "licenses",
        icon: "key",
        url: "/users-licenses",
        disabled: adminOptionsDisable,
      },
      {
        label: "Subscription Plans",
        id: "plans",
        icon: "card_membership",
        url: "/subscription-plans",
        disabled: adminOptionsDisable,
      },
      {
        label: "Accounts",
        id: "accounts",
        icon: "payments",
        url: "/accounts/" + currentYear,
        disabled: adminOptionsDisable,
      },
      {
        label: "Sync Queue",
        id: "queueWorkers",
        icon: "engineering",
        url: "/queue-workers",
        disabled: adminOptionsDisable,
      },
    ],
    [adminOptionsDisable, currentYear]
  );

  const adminUserOptions = useMemo(() => {
    return userRole == "admins" && app.showAdminNav ? (
      <SideMenuGroup
        label={"Platform settings"}
        id={"platform_settings_menu"}
        icon={"settings"}
      >
        {adminOptions.map((i, idx) => {
          return (
            <SideMenuItem
              key={"aol-" + idx}
              currentSidebarId={props.sidebarId}
              {...i}
            />
          );
        })}
      </SideMenuGroup>
    ) : null;
  }, [userRole, props.sidebarId, app.showAdminNav]);

  const menuOptions = useMemo(
    () =>
      userMenuOptions.map((item, idx) => {
        if (item.adminOnly && userRole !== "admins") {
          return null;
        }

        if (item.hidden && adminOptionsDisable) {
          if (window.location.hostname !== `dev.${appConfig.domain}`) {
            return null;
          }
        }

        return (
          <SideMenuItem
            key={item.id}
            currentSidebarId={props.sidebarId}
            {...item}
          />
        );
      }),
    [props.sidebarId]
  );

  const widgetsMenu = useMemo(() => {
    if (widgetMenuOptions.length === 0) {
      return null;
    }

    return (
      <SideMenuGroup label={"Widgets"} id={"widgets_menu"} icon={"widgets"}>
        {widgetMenuOptions.map((i, idx) => {
          return (
            <SideMenuItem
              key={"wmo-" + idx}
              currentSidebarId={props.sidebarId}
              {...i}
            />
          );
        })}
      </SideMenuGroup>
    );
  }, [props.sidebarId]);

  return (
    <div id="sidebar-menu" className="sidebar-menu">
      <ul>
        <SideMenuItem
          key={"dashboard"}
          currentSidebarId={props.sidebarId}
          label={"Dashboard"}
          icon={"home"}
          id={"dashboard"}
          url={"/"}
        />
        {widgetsMenu}
        {menuOptions}

        <ResendActivationInfo />
        <UpgradePlanInfo />
        <FailedPayment1Info />
        <FailedPayment2Info />
        <SyncIssuesInfo />
        <BecomeAffiliateInfo />

        {adminUserOptions}
      </ul>
    </div>
  );
};

SidebarMenu.defaultProps = {};

SidebarMenu.propTypes = {
  sidebarId: PropTypes.string,
};

export default SidebarMenu;
