/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/12/2018.
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { TabOption } from "./TabOption";

export const Tab = (props) => {
  const {
    show,
    rules,
    sections,
    subheader,
    subSectionHeader,
    icon,
    title,
    searchQuery,
  } = props;
  /**
   *
   * @param e
   */
  const onTabClick = useCallback((e) => {
    e.preventDefault();
    return false;
  }, []);

  const optionsElement = !searchQuery.length ? (
    <ul className="list-unstyled" style={{ display: "none" }}>
      {rules}
    </ul>
  ) : null;

  const menuArrow = !searchQuery.length ? (
    <span className="menu-arrow position-relative" />
  ) : null;

  return (
    <div
      className={classnames(
        "card-box sidebar-panel-box sidebar-menu design-tab",
        show ? "d-display" : "d-none"
      )}
    >
      <ul>
        <li className="submenu">
          <a className="design-tab-menu " href="#" onClick={onTabClick}>
            <span>
              <h4 className={"d-flex align-items-center"}>
                {/*<i className={"fa " + icon} aria-hidden="true" />*/}
                <i className={"material-icons icon-lg"} aria-hidden="true">
                  {icon}
                </i>
                {title}
              </h4>
            </span>
            {menuArrow}
          </a>
          {optionsElement}
        </li>
        <TabOption header={subheader} options={rules} />
        <TabOption header={subSectionHeader} options={sections} />
      </ul>
    </div>
  );
};

Tab.defaultProps = {
  show: true,
  subheader: null,
  subSectionHeader: null,
  sections: [],
  rules: [],
  searchQuery: "",
};

Tab.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object])
    .isRequired,
  icon: PropTypes.string.isRequired,
  show: PropTypes.bool,
  subheader: PropTypes.object,
  sections: PropTypes.array,
  rules: PropTypes.array,
  subSectionHeader: PropTypes.object,
  searchQuery: PropTypes.string,
};

export default Tab;
