import { MCT } from "../../../consts";

const showIfEverGreen = [
  {
    field: "eventsToShow",
    value: "evergreen",
    defaultValue: "defaults",
  },
];

export const definitions = {
  title: "Mailchimp",
  icon: "padding",
  groups: [
    {
      label: "Events control",
      options: [
        {
          title: "Method to show events",
          templates: [MCT.list, MCT.compact],
          name: "eventsToShow",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Calendar default (one-time)", value: "defaults" },
                { label: "Evergreen (recurring)", value: "evergreen" },
              ],
            },
          },
        },
        {
          title: "Show events starting from",
          templates: [MCT.list, MCT.compact],
          name: "startDate",
          defaultValue: "mon",
          tooltip: null,
          show: showIfEverGreen,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "next Monday", value: "mon" },
                { label: "next Tuesday", value: "tue" },
                { label: "next Wednesday", value: "wed" },
                { label: "next Thursday", value: "thu" },
                { label: "next Friday", value: "fri" },
                { label: "next Saturday", value: "sat" },
                { label: "next Sunday", value: "sun" },
                {
                  label: "first day of the next month",
                  value: "first_day_of_the_next_month",
                },
              ],
            },
          },
        },
        {
          title: "Limit evnets by number of days",
          templates: [MCT.list, MCT.compact],
          name: "daysLimit",
          defaultValue: "all",
          tooltip: null,
          control: {
            kind: "radio",
            options: {
              radioOptions: [
                {
                  title: "all days",
                  value: "all",
                  template: [MCT.list, MCT.compact],
                },

                {
                  title: "Show events for the next",
                  value: "daysLimit",
                  template: [MCT.list, MCT.compact],
                  input: {
                    defaultValue: 7,
                    postLabel: "days",
                    type: "number",
                  },
                },
              ],
            },
          },
        },
        {
          title: "Limit events",
          templates: [MCT.list, MCT.compact],
          name: "eventsLimit",
          defaultValue: "all",
          tooltip: null,
          control: {
            kind: "radio",
            options: {
              radioOptions: [
                {
                  title: "Show all events matching the criteria",
                  value: "all",
                  template: [MCT.list, MCT.compact],
                },
                {
                  title: "Show",
                  value: "upcoming-limit",
                  template: [MCT.list, MCT.compact],
                  input: {
                    defaultValue: 3,
                    postLabel: "upcoming events",
                    type: "number",
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Display information",
      options: [
        {
          title: "Show event title",
          templates: [MCT.list, MCT.compact],
          name: "showTitle",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show date",
          templates: [MCT.list, MCT.compact],
          name: "showDate",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show location",
          templates: [MCT.list, MCT.compact],
          name: "showLocation",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show description",
          templates: [MCT.list, MCT.compact],
          name: "showDescription",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Limit description length",
          templates: [MCT.list, MCT.compact],
          name: "limitDescription",
          defaultValue: 1,
          tooltip: null,
          show: [
            {
              field: "showDescription",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show Read more",
          templates: [MCT.list, MCT.compact],
          name: "showReadMore",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show image",
          templates: [MCT.list, MCT.compact],
          name: "showImage",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Design",
      options: [
        {
          title: "Background",
          templates: [MCT.list, MCT.compact],
          name: "bgColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: ["._____NONE"],
              property: "background-color",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
