/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/04/2022.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import WidgetPreview from "./../component/WidgetSettings/WidgetPreview";
import SetupTab from "./AlertPage/SetupTab";
import DesignTab from "./../component/WidgetSettings/DesignTab";
import InstallTab from "./AlertPage/InstallTab/InstallTab";
import { store } from "../store";
import {
  alertFeaturesCheck,
  alertSettingsValidator,
  updateJquery,
} from "../tools";
import { useAlertStore } from "../../../hooks/redux/alert";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { useUserStore } from "../../../hooks/redux/user";
import { useAppStore } from "../../../hooks/redux/app";
import { useDomainsStore } from "../../../hooks/redux/domains";
import WidgetPreviewHeader from "../component/WidgetSettings/WidgetPreviewHeader";
import { useBuilderStore } from "../../../hooks/redux/builder";
import IntegrationContext, {
  getStrategy,
} from "../../alerts/strategies/IntegrationContext";
import { store as NotificationComponent } from "react-notifications-component";
import NotificationPopup from "../component/NotificationPopup";
import NoItemsAlert from "./AlertPage/NoItemsAlert";
import IntegrationIssueNotification from "../component/IntegrationIssueNotification";
import { PLANS } from "../../../consts";
import BrowserWindowMockup from "./AlertPage/BrowserWindowMockup";
import EventsPreview from "./AlertPage/EventsPreview";
import Alert from "./../../alerts/components/Alert";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import useSetupChangesTrackerHook from "../hooks/useSetupChangesTrackerHook";

window.__DCE = window.__DCE || {};
window.__DCE.alerts = window.__DCE.alerts || {};

const AlertPage = (props) => {
  const uuid = props.match.params.uuid;
  const { app } = useAppStore();

  const {
    alert,
    deleteAlert,
    fetchAlert,
    setAlert,
    updateAlert,
    alertEvents,
    showAlert,
  } = useAlertStore();

  const { overwriteDesignProp } = useBuilderStore();

  const { integrations } = useIntegrationsStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { clearErrors } = useAppStore();
  const { domains } = useDomainsStore();

  const history = useHistory();
  const [state, setState] = useState({
    alertModel: null,
    errors: {},
  });

  const { trackChanges, setOriginValues, hasChanges } =
    useSetupChangesTrackerHook();
  const [savePointEventsSettings, setSavePointEventsSettings] = useState(null);

  // -------------------------------------------------------------------
  /**
   * Initialization of the Widget. It gets only UUID from query param.
   * 1. Load widget data from API
   * 2. Update alert_filters_uuids alert model state
   * After these steps, widget is initialized and ready to render
   * @type {boolean}
   */

  const isWidgetInitialized = useMemo(() => {
    // model is loadedi
    // widget filters are loaded
    // [model state is updated]
    // integration filters are loaded
    return alert.model &&
      alert.model.slug &&
      alert.model.integration &&
      state.alertModel
      ? true
      : false;
  }, [alert.model, state.alertModel]);

  const strategy = useMemo(
    () =>
      getStrategy(
        isWidgetInitialized ? alert.model.integration.type : null,
        alert.model
      ),
    [isWidgetInitialized, alert.model]
  );

  useEffect(() => {
    (async () => {
      await fetchAlert(props.match.params.uuid);
    })();

    return () => {
      setAlert(null);
    };
  }, []);

  useEffect(() => {
    if (alert.model) {
      setState({
        ...state,
        alertModel: {
          ...alert.model,
          integration_uuid: alert.model.integration.uuid,
        },
      });
      setOriginValues({ ...alert.model });
    }
  }, [alert.model]);

  useEffect(() => {
    if (alert.fetchSuccess) {
      if (!alert.model.integration) {
        //history.push("/dashboard");
      }
    }

    if (alert.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }
  }, [alert.fetchSuccess]);

  // -------------------------------------------------------------------

  useEffect(() => {
    if (alert.updateSuccess) {
      setSavePointEventsSettings(
        Object.assign({}, alert.model.widget_settings)
      );

      setTimeout(clearErrors, 4500);
      setOriginValues(alert.model);
    }
  }, [alert.updateSuccess]);

  /**
   * track changes if design settings were updated
   */
  useEffect(() => {
    if (isWidgetInitialized && app.widgetSettingsUpdateTimestamp) {
      trackChanges("widget_settings", alert.model.widget_settings);
    }
  }, [app.widgetSettingsUpdateTimestamp, isWidgetInitialized]);

  useEffect(() => {
    if (isWidgetInitialized) {
      setSavePointEventsSettings(
        Object.assign({}, alert.model.widget_settings)
      );
    }
  }, [isWidgetInitialized]);

  useEffect(() => {
    if (!alert.show) {
      setTimeout(showAlert, 3000);
    }
  }, [alert.show]);

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const updatedModel = { ...state.alertModel };

    trackChanges(fieldName, value);
    updatedModel[fieldName] = value;

    setState({
      alertModel: updatedModel,
      errors: alertSettingsValidator(updatedModel),
    });
  };

  /**
   * @param {string} fieldName
   * @param {string|array|object} value
   * @type {(function(*, *))|*}
   */
  const onUpdateFilterSettings = useCallback(
    (field, value) => {
      const updatedModel = {
        ...state.alertModel,
      };
      updatedModel.filter_settings = {
        ...(updatedModel.filter_settings || {}),
      };
      updatedModel.filter_settings[field] = value;
      trackChanges("filter_settings", updatedModel.filter_settings);

      setState({
        alertModel: updatedModel,
        errors: alertSettingsValidator(updatedModel),
      });
    },
    [state.alertModel]
  );

  /**
   *
   */
  const onDeleteClick = async (e) => {
    e.preventDefault();

    if (alert.fetch || alert.delete) {
      return false;
    }

    if (
      window.confirm(
        "Do you really want to delete this widget? This action is irreversible."
      )
    ) {
      await deleteAlert(uuid);
    }

    if (store.getState().alert.deleteSuccess) {
      history.push("/alerts");
    }

    return false;
  };

  const validateSettings = (data) => {
    const issues = alertFeaturesCheck(data, currentOrganization);

    if (issues.length) {
      setState({
        ...state,
        errors: issues,
      });

      return false;
    }

    return true;
  };

  const onUpdateStatus = useCallback(
    (value) => {
      const updatedModel = {
        ...state.alertModel,
      };

      updatedModel.available = value;

      const isConfigValid = validateSettings(updatedModel);
      const hasValidSettings = alertSettingsValidator(updatedModel);
      setState({ ...state, errors: hasValidSettings });

      if (!isConfigValid || Object.keys(hasValidSettings).length) {
        return false;
      }

      setState({
        ...state,
        alertModel: updatedModel,
      });

      _onSettingsSubmit(updatedModel);
    },
    [state.alertModel]
  );

  /**
   *
   */
  const onSettingsSubmit = async () => {
    const data = { ...state.alertModel };

    const hasValidSettings = alertSettingsValidator(data);
    setState({ ...state, errors: hasValidSettings });
    if (Object.keys(hasValidSettings).length) {
      return false;
    }
    _onSettingsSubmit(data);
  };

  /**
   *
   * @param data
   * @returns {Promise<void>}
   * @private
   */
  const _onSettingsSubmit = async (data) => {
    data.widget_settings = { ...alert.model.widget_settings };

    // if integration has changed, clear integration filters
    if (data.integration_uuid != alert.model.integration.uuid) {
      data.filter_settings = null;
    }

    await updateAlert(uuid, data);

    const alertStore = store.getState().alert;

    if (
      alertStore.updateSuccess &&
      alertStore.model.integration.uuid != alert.model.integration.uuid
    ) {
      //const _strategy = getStrategy(alertStore.model.integration.type);
      //await fetchIntegrationFilters(alertStore.model.integration.uuid, _strategy.availableFilters);
      //await fetchAlertFilters(alertStore.model.uuid);
    }
  };

  /**
   *
   */
  const onSettingsRestore = () => {
    if (
      window.confirm(
        "This will remove all the changes you have done since the last SAVE. Are you sure?"
      )
    ) {
      overwriteDesignProp(savePointEventsSettings.design);
    }
  };

  const loading =
    alert.update ||
    alert.fetch ||
    alert.delete ||
    !alert.model ||
    !alert.model.template ||
    !isWidgetInitialized;

  const settingsTabsOptions = isWidgetInitialized
    ? [
        {
          label: "Setup",
          id: "setup_tab",
          additionalClassName: "active show",
        },
        {
          label: "Design",
          id: "design_tab",
          additionalClassName: "",
        },
        {
          label: "Install",
          id: "install_tab",
          additionalClassName: "",
        },
      ]
    : [];

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const designTab = isWidgetInitialized ? (
    <DesignTab
      disabled={loading}
      onSubmit={onSettingsSubmit}
      onRestore={onSettingsRestore}
      widget={alert}
      hasSetupChanged={hasChanges}
    />
  ) : null;

  const setupTab = isWidgetInitialized ? (
    <SetupTab
      alert={alert}
      integrations={integrations.collection}
      disabled={loading}
      onSubmit={onSettingsSubmit}
      isNew={false}
      overwriteDesignProp={overwriteDesignProp}
      alertModel={state.alertModel || {}}
      errors={state.errors}
      onUpdateField={onUpdateField}
      onUpdateFilterSettings={onUpdateFilterSettings}
      hasSetupChanged={hasChanges}
    />
  ) : null;

  const installTab = isWidgetInitialized ? (
    <InstallTab
      widget={alert}
      disabled={loading}
      onSubmit={onSettingsSubmit}
      isNew={false}
      hasDomains={domains.collection.length ? true : false}
      widgetModel={state.alertModel || {}}
      errors={state.errors}
      onUpdateField={onUpdateField}
      widgetTypeName={"Widget"}
      shareableUrl={`${window.location.origin}/g/${alert.model.slug}`}
      onUpdateStatus={onUpdateStatus}
      widgetType={"Alert"}
      recommendedPlan={PLANS.starter}
    />
  ) : null;

  const alertSidebar = (
    <div
      className="col-lg-3 message-view chat-profile-view chat-sidebar"
      id="chat_sidebar"
    >
      <div className="chat-window video-window">
        <div className="fixed-header">
          <ul className="nav nav-tabs nav-tabs-bottom">{settingsTabs}</ul>
        </div>

        <div className="tab-content chat-contents">
          {setupTab}

          {designTab}

          {installTab}
        </div>
      </div>
    </div>
  );

  const integrationIssuesInfo = isWidgetInitialized ? (
    <IntegrationIssueNotification
      integration_uuid={alert.model.integration.uuid}
    />
  ) : null;

  const noItemsToDisplayInfo =
    !integrationIssuesInfo &&
    isWidgetInitialized &&
    alertEvents.fetchSuccess &&
    !alertEvents.collection.length ? (
      <NoItemsAlert alert={alert} />
    ) : null;

  const alertPreview = isWidgetInitialized ? (
    <React.Fragment>
      <WidgetPreview
        key={alert.model.integration.type || new Date()}
        uuid={uuid}
        widget={alert}
        widgetKind={"alerts"}
      >
        <BrowserWindowMockup>
          <div id={uuid}>
            <Alert widgetUUID={uuid} embedded={false} />
          </div>
        </BrowserWindowMockup>
      </WidgetPreview>

      {integrationIssuesInfo}
      {noItemsToDisplayInfo}
    </React.Fragment>
  ) : null; /*<LoadingIndicator />*/

  return (
    <PageTemplate
      title={isWidgetInitialized ? alert.model.name : "Loading..."}
      sidebarId="alerts"
      icon={"campaign"}
      className={"page-wrapper-widget"}
      headerControls={[
        <WidgetPreviewHeader key="gpbc" onDelete={onDeleteClick} />,
      ]}
    >
      <IntegrationContext.Provider value={strategy}>
        <div className="chat-main-row">
          <div className="chat-main-wrapper">
            <div className="col-lg-9 message-view task-view">
              <div className="chat-window bg-white">
                {alertPreview}
                <EventsPreview isLoading={loading} />
              </div>
            </div>

            {alertSidebar}
          </div>
        </div>

        <div className="sidebar-overlay" data-reff="#chat_sidebar" />
      </IntegrationContext.Provider>
    </PageTemplate>
  );
};

export default AlertPage;
