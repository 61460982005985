/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React, { useCallback, useContext, useRef } from "react";

import PropTypes from "prop-types";
import DesignTooltip from "./DesignTooltip";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const FontSizeSetup = (props) => {
  const { name, defaultValue } = props;

  const nameOption = name + "Option";
  const inputName = name + "-input";

  const widgetSettingsContext = useContext(WidgetSettingsContext);
  const inputValue = widgetSettingsContext[name] || "default";
  const optionValue = widgetSettingsContext[nameOption] || "default";
  const customValue = useRef(null);
  const customFactor = useRef(null);

  /**
   *
   * @param value
   * @returns {{factor: (*|string), value: (string|string)}}
   */
  const parseCustomValue = (value) => {
    const sizeMatch = value.match(/\d+\.?\d?/);
    const newSize = sizeMatch ? sizeMatch[0] + "" : "";

    return {
      value: newSize,
      factor: newSize.length ? value.replace(newSize, "") : "px",
    };
  };

  /**
   *
   * @param event
   */
  const computeValue = () => {
    const size = customValue.current.value;
    const factor = customFactor.current.value;

    props.updateDesignProp([
      {
        field: props.name,
        value: size + factor,
      },
    ]);
  };

  /**
   *
   * @param props
   * @returns {function(...[*]=)}
   */
  const updateDesignProp = useCallback(
    (properties) => (e) => {
      props.updateDesignProp(properties);
    },
    [props.updateDesignProp]
  );

  if (!props.show) {
    return null;
  }

  const disabled = optionValue === "default";

  const isCustom = optionValue === "custom";

  let initialValue = null;

  if (isCustom) {
    initialValue = parseCustomValue(inputValue);
  }

  return (
    <div className={"design-element"}>
      <label className="option-label">
        <span className={"option-title"}>{props.title}</span>
        <DesignTooltip help={props.tooltip} />
      </label>

      <div className="form-group color-picker-row">
        <div className="col-xs-6">
          <div className="radio">
            <label>
              <input
                type="radio"
                name={inputName}
                checked={optionValue === "default"}
                onChange={updateDesignProp([
                  { field: nameOption, value: "default" },
                  { field: name, value: defaultValue },
                ])}
              />{" "}
              Default
            </label>
          </div>

          <div className="radio">
            <label>
              <input
                type="radio"
                name={inputName}
                checked={optionValue === "inherit"}
                onChange={updateDesignProp([
                  { field: nameOption, value: "inherit" },
                  { field: name, value: "inherit" },
                ])}
              />{" "}
              Inherit
            </label>
          </div>

          <div className="radio">
            <label>
              <input
                type="radio"
                name={inputName}
                checked={optionValue === "custom"}
                onChange={updateDesignProp([
                  { field: nameOption, value: "custom" },
                  { field: name, value: defaultValue },
                ])}
              />{" "}
              Custom
            </label>
            <div className="input-group">
              <input
                ref={customValue}
                disabled={!isCustom}
                value={!isCustom ? "" : initialValue.value}
                onChange={computeValue}
                type="number"
                className="form-control"
                placeholder={props.placeholder}
              />
              <div className="input-group-append">
                <select
                  ref={customFactor}
                  disabled={!isCustom}
                  className="form-control"
                  size={1}
                  value={!isCustom ? "" : initialValue.factor}
                  onChange={computeValue}
                >
                  <option value={"px"}>px</option>
                  <option value={"%"}>%</option>
                  <option value={"em"}>em</option>
                  <option value={"rem"}>rem</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-6" />
      </div>
    </div>
  );
};

FontSizeSetup.defaultProps = {
  show: true,
};

FontSizeSetup.propTypes = {
  ...BuilderInputSetupPropTypes,
  control: PropTypes.shape({
    kind: PropTypes.oneOf(["fontSize"]),
    options: PropTypes.shape({
      placeholder: PropTypes.string,
      inherit: PropTypes.bool,
    }),
  }),
};

export default FontSizeSetup;
