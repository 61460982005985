/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Tab as OptionTab } from "../WidgetSettings/Tab";
import Group from "./Group";
import { calculateDependency } from "../../../../builder";
import { useAppStore } from "../../../../hooks/redux/app";

const Tab = (props) => {
  const { definition, widget, subheader, subSectionHeader, searchQuery } =
    props;
  const { app } = useAppStore();

  // check if groups have at least one option
  const hasOption = useMemo(
    () =>
      definition.rules.find((group) => {
        return group.options.find((option) => {
          if (!option.show) {
            return true;
          }
          return calculateDependency(
            option.show,
            widget.model.widget_settings[props.settingsName]
          );
        });
      }),
    [
      definition.rules,
      widget.model.widget_settings,
      app.widgetSettingsUpdateTimestamp,
    ]
  );

  // check if groups have at least one section
  const hasSection = useMemo(
    () =>
      definition.sections.find((group) => {
        return group.options.find((option) => {
          if (!option.show) {
            return true;
          }
          return calculateDependency(
            option.show,
            widget.model.widget_settings[props.settingsName]
          );
        });
      }),
    [
      definition.sections,
      widget.model.widget_settings[props.settingsName],
      app.widgetSettingsUpdateTimestamp,
    ]
  );

  if (!hasOption && !hasSection) {
    return null;
  }

  const rules = definition.rules
    .map((group, idx) => {
      return (
        <Group
          key={`dg-${props.settingsName}-${idx}-${group.label}`}
          group={group}
          widget={widget}
          searchQuery={searchQuery}
          settingsName={props.settingsName}
        />
      );
    })
    .filter((i) => {
      return i;
    });

  const sections = definition.sections
    .map((group, idx) => {
      return (
        <Group
          key={`dg-${props.settingsName}-${idx}-${group.label}`}
          group={group}
          widget={widget}
          searchQuery={searchQuery}
          settingsName={props.settingsName}
        />
      );
    })
    .filter((i) => {
      return i;
    });

  return (
    <OptionTab
      title={definition.title}
      icon={definition.icon}
      subheader={subheader}
      sections={sections}
      subSectionHeader={subSectionHeader}
      searchQuery={searchQuery}
      rules={rules}
    />
  );
};

Tab.defaultProps = {
  settingsName: "design",
};

Tab.propTypes = {
  definition: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]),
    icon: PropTypes.string.isRequired,
    rules: PropTypes.array,
    sections: PropTypes.array,
  }),
  widget: PropTypes.object.isRequired,
  searchQuery: PropTypes.string,
  subSectionHeader: PropTypes.object,
  settingsName: PropTypes.string,
};

export default Tab;
