/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/06/2022.
 */

import React, { useState, useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import SubscriptionAccess from "../../../component/SubscriptionAccess";
import IntegrationContext from "../../../../alerts/strategies/IntegrationContext";
import { useFiltersStore } from "../../../../../hooks/redux/filters";
import ExpandableAlert from "../../../component/ExpandableAlert";
import RadioInput from "../../../component/WidgetSettings/RadioInput";
import { IT, PLANS } from "../../../../../consts";
import Filters from "../../../component/Filters/Filters";
import useFiltersHook from "../../../hooks/useFiltersHook";
import { useAlertStore } from "../../../../../hooks/redux/alert";
import { useCurrentOrganizationStore } from "../../../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../../../helpers/organization";
import Accordion from "../../../component/WidgetSettings/Acordion";
import appConfig from "../../../../../appConfig";

const allowedPlans = [];
const EventsFiltering = (props) => {
  const integrationStrategy = useContext(IntegrationContext);

  const { filters, fetchIntegrationFilters, clearFilters } = useFiltersStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const { alert } = useAlertStore();
  const { widgetModel } = props;
  const subscription = hasSubscription(currentOrganization);

  const { availableFilters } = useFiltersHook({
    widget: alert,
    integrationStrategy,
  });

  const isPrivateEventsAllowed =
    widgetModel &&
    widgetModel.integration &&
    (widgetModel.integration.type !== IT.ccb ||
      (widgetModel.integration.type === IT.ccb &&
        subscription &&
        [PLANS.medium, PLANS.highest].indexOf(subscription.code) >= 0))
      ? true
      : false;

  useEffect(() => {
    if (!filters.fetchIntegrationFilters) {
      fetchIntegrationFilters(
        props.widget.model.integration.uuid,
        integrationStrategy.availableFilters,
        props.widget.model.integration.type
      );
    }

    return () => {
      clearFilters();
    };
  }, [props.integrationModel]);

  /**
   *
   * @param e
   */
  const onFilteringCheckboxChange = (field) => (e) => {
    props.onUpdateField(field, !(props.widgetModel[field] * 1) ? 1 : 0);
  };

  const { widget } = props;

  const showInfoAlert = integrationStrategy.filterLabelCTA ? (
    <ExpandableAlert
      errorLevel={"info"}
      header={"How to use filters below?"}
      body={integrationStrategy.filterLabelCTA}
    />
  ) : null;

  const privateEventsError =
    widgetModel.show_private_events * 1 && !props.integrationModel.sync_private
      ? integrationStrategy.privateEventsSettingsWarning(
          widgetModel.integration.uuid
        )
      : null;

  const privateEventsWarning =
    widgetModel.show_private_events * 1 ? (
      <ExpandableAlert
        icon={null}
        header={"Recommended helpful tip:"}
        body={
          <span>
            We recommend using the events filtering option available below.
            Publishing your calendar without events filtering will reveal{" "}
            <strong>all</strong> the events!
          </span>
        }
        errorLevel={"info"}
      ></ExpandableAlert>
    ) : null;

  const privateEventsControl =
    widgetModel.uuid && integrationStrategy.privateEventsControl ? (
      <React.Fragment>
        <RadioInput
          fieldName={"show_private_events"}
          label={integrationStrategy.privateEventsControl.toggleButtonLabel}
          onChange={onFilteringCheckboxChange}
          checked={widgetModel.show_private_events * 1}
          tooltip={
            integrationStrategy.privateEventsControl.privateEventsTooltip
          }
          isPremium={!isPrivateEventsAllowed}
        />
        {privateEventsError}
        {privateEventsWarning}
      </React.Fragment>
    ) : null;

  const groupSpecificFiltering =
    widgetModel.uuid && widgetModel.integration.type === IT.pco ? (
      <React.Fragment>
        <RadioInput
          fieldName={"has_group_events_included"}
          label={"Include group-specific events"}
          onChange={onFilteringCheckboxChange}
          checked={widgetModel.has_group_events_included * 1}
          isPremium={!isPrivateEventsAllowed}
        />
      </React.Fragment>
    ) : null;

  const widgetFilters = widgetModel.filter_settings
    ? widgetModel.filter_settings.filters
    : null;

  return (
    <Accordion title={"Filtering"} defaultExpanded={true}>
      <form action="#">
        {privateEventsControl}
        {groupSpecificFiltering}

        <RadioInput
          fieldName={"show_events_with_images"}
          label={"Show events with images only"}
          onChange={onFilteringCheckboxChange}
          checked={widgetModel.show_events_with_images * 1}
          tooltip={{
            url: `${appConfig.helpdeskAddress}/en/articles/4901525-setup-events-show-events-with-images-only`,
            message: "Show events with images only",
          }}
        />
      </form>

      <SubscriptionAccess
        allowedPlans={allowedPlans}
        CTAMessage={
          "You can limit events by selecting specific filters. Upgrade your subscription to unlock this functionality. "
        }
      >
        {showInfoAlert}
        <form action="#">
          <Filters
            availableFilters={availableFilters}
            filters={widgetFilters}
            onChange={props.onUpdateFilters}
          />
        </form>
      </SubscriptionAccess>
    </Accordion>
  );
};

EventsFiltering.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetModel: PropTypes.object.isRequired,
  integrationModel: PropTypes.object.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateFilters: PropTypes.func.isRequired,
};

export default EventsFiltering;
