import React, { useEffect } from "react";
import { Route, Switch, withRouter, matchPath } from "react-router";

import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import PasswordReminderPage from "./pages/PasswordReminderPage";
import DashboardPage from "./pages/DashboardPage";
import SettingsPage from "./pages/SettingsPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import IntegrationsPage from "./pages/IntegrationsPage";
import DomainsPage from "./pages/DomainsPage";
import CalendarsPage from "./pages/CalendarsPage";
import PlansPage from "./pages/PlansPage";
import NewCalendarPage from "./pages/NewCalendarPage";
import CalendarPage from "./pages/CalendarPage";
import UsersPage from "./pages/UsersPage";
import CouponsPage from "./pages/CouponsPage";
import LicensesPage from "./pages/LicensesPage";
import OrganizationsLicensesPage from "./pages/OrganizationsLicensesPage";
import SubscriptionPlansPage from "./pages/SubscriptionPlansPage";
import PayoutsPage from "./pages/PayoutsPage";
import PageTemplate from "./templates/PageTemplate";
import AttachIntegrationPage from "./pages/AttachIntegrationPage";
import GroupsPage from "./pages/GroupsPage";
import NewGroupPage from "./pages/NewGroupPage";
import GroupPage from "./pages/GroupPage";
import AttachGroupIntegrationPage from "./pages/AttachGroupIntegrationPage";
import AccountVerificationPage from "./pages/AccountVerificationPage";
import SelectIntegrationPage from "./pages/SelectIntegrationPage";
import QueueWorkersPage from "./pages/QueueWorkersPage";
import QueuedSyncJobs from "./pages/QueuedSyncJobs";
import NewAlertPage from "./pages/NewAlertPage";
import AlertsPage from "./pages/AlertsPage";
import AlertPage from "./pages/AlertPage";
import AttachAlertIntegrationPage from "./pages/AttachAlertIntegrationPage";
import IntegrationAccessCheckPage from "./pages/IntegrationAccessCheckPage";
import QueuedEmbeddingJobs from "./pages/QueuedEmbeddingJobs";
import NewSignupPage from "./pages/NewSignupPage";
import SignupsPage from "./pages/SignupsPage";
import SignupPage from "./pages/SignupPage";
import OrganizationsPage from "./pages/OrganizationsPage";
import appConfig from "../../appConfig";
import SelectCalendarsPage from "./pages/SelectCalendarsPage";
import OrganizationMembersPage from "./pages/OrganizationMembersPage";
import OrganizationSettingsPage from "./pages/OrganizationSettingsPage";
import InvitationVerificationPage from "./pages/InvitationVerificationPage";
import QueuedWebhookJobs from "./pages/QueuedWebhookJobs";
import OrganizationBrandingPage from "./pages/OrganizationBrandingPage";
import AffiliatesPage from "./pages/AffiliatesPage";
import PluginsAuthPage from "./pages/PluginsAuthPage";
import AttachSignupIntegrationPage from "./pages/UsersPage/AttachSignupIntegrationPage";
import QueuedICSUpdatePage from "./pages/QueuedICSUpdatePage";
import QueuedICSCleanPage from "./pages/QueuedICSCleanPage";

let previousMainComponentLocation = null;
const MainRoutesComponent = React.memo(
  (props) => {
    // console.log("MainRoutesComponent", props);
    useEffect(() => {
      previousMainComponentLocation = props.location;
    }, []);

    const SubscriptionPlansPageComponent = () => {
      return (
        <SubscriptionPlansPage
          subscriptionPlansActions={props.subscriptionPlansActions}
        />
      );
    };

    const isAdmin = props.user.model && props.user.model.role == "admins";

    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route
            exact
            path="/account-verification"
            component={AccountVerificationPage}
          />
          <Route exact path="/logout" component={LoginPage} />
          <Route
            exact
            path="/password-reminder"
            component={PasswordReminderPage}
          />
          <Route
            exact
            path="/select-integration"
            component={SelectIntegrationPage}
          />
          <Route
            exact
            path="/integrations/select-calendars/:uuid"
            component={SelectCalendarsPage}
          />
          <Route
            exact
            path="/accept_invitation/:email/:invitationHash"
            component={InvitationVerificationPage}
          />
          <Route
            path="/password-reset/:email/:token"
            component={PasswordResetPage}
          />

          <Route
            exact
            path="/"
            component={props.user.model ? DashboardPage : LoginPage}
          />

          {props.user.model ? (
            <Switch>
              <Route exact path="/dashboard" component={DashboardPage} />
              <Route exact path="/settings" component={SettingsPage} />
              <Route
                exact
                path="/organization-settings"
                component={OrganizationSettingsPage}
              />
              <Route
                exact
                path="/branding"
                component={OrganizationBrandingPage}
              />
              <Route exact path="/integrations" component={IntegrationsPage} />
              <Route
                exact
                path="/integrations/check-access/:type/:uuid"
                component={IntegrationAccessCheckPage}
              />
              <Route exact path="/domains" component={DomainsPage} />
              <Route exact path="/calendars" component={CalendarsPage} />
              <Route exact path="/groups" component={GroupsPage} />
              <Route exact path="/alerts" component={AlertsPage} />
              <Route exact path="/signups" component={SignupsPage} />
              <Route exact path="/billing" component={PlansPage} />
              <Route exact path="/calendar/new" component={NewCalendarPage} />
              <Route exact path="/group/new" component={NewGroupPage} />
              <Route exact path="/alert/new" component={NewAlertPage} />
              <Route exact path="/signup/new" component={NewSignupPage} />
              <Route path="/calendar/:uuid" component={CalendarPage} />
              <Route exact path="/group/:uuid" component={GroupPage} />
              <Route exact path="/alert/:uuid" component={AlertPage} />
              <Route exact path="/signup/:uuid" component={SignupPage} />
              <Route
                path="/attach-integration/:uuid"
                component={AttachIntegrationPage}
              />
              <Route
                exact
                path="/group/attach-integration/:uuid"
                component={AttachGroupIntegrationPage}
              />
              <Route
                exact
                path="/alert/attach-integration/:uuid"
                component={AttachAlertIntegrationPage}
              />
              <Route
                exact
                path="/signup/attach-integration/:uuid"
                component={AttachSignupIntegrationPage}
              />
              <Route
                exact
                path={"/members"}
                component={OrganizationMembersPage}
              />
              <Route exact path={"/plugins/auth"} component={PluginsAuthPage} />
              <Route exact path="/pricing" component={PlansPage} />
              <Route exact path="/affiliate" component={AffiliatesPage} />
              {isAdmin && <Route path="/users/:page?" component={UsersPage} />}
              {isAdmin && (
                <Route
                  path={`/${appConfig.links.organizations}/:page?`}
                  component={OrganizationsPage}
                />
              )}
              {isAdmin && (
                <Route path="/coupons/:page?" component={CouponsPage} />
              )}
              {isAdmin && (
                <Route path="/licenses/:page?" component={LicensesPage} />
              )}{" "}
              {isAdmin && (
                <Route
                  path="/users-licenses/:page?"
                  component={OrganizationsLicensesPage}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path="/subscription-plans"
                  component={SubscriptionPlansPageComponent}
                />
              )}
              {isAdmin && (
                <Route path="/accounts/:year" component={PayoutsPage} />
              )}
              {isAdmin && (
                <Route
                  exact
                  path="/queue-workers"
                  component={QueueWorkersPage}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path="/sync-jobs/:page?"
                  component={QueuedSyncJobs}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path="/webhook-jobs/:page?"
                  component={QueuedWebhookJobs}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path="/embedding-jobs/:page?"
                  component={QueuedEmbeddingJobs}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path="/ics-update-jobs/:page?"
                  component={QueuedICSUpdatePage}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path="/ics-clean-jobs/:page?"
                  component={QueuedICSCleanPage}
                />
              )}
              {/*<Route path={"*"} component={NotFoundPage} />*/}
            </Switch>
          ) : null}
          <Route component={props.user.model ? LoginPage : PageTemplate} />
        </Switch>
      </React.Fragment>
    );
  },
  (oldProps, newProps) => {
    // console.log("MainRoutesComponent", oldProps, newProps);
    if (
      !previousMainComponentLocation ||
      previousMainComponentLocation.pathname !== window.location.pathname
    ) {
      // if next location is one of the modals with static path
      const modalLinkIndex = oldProps.modalLinks.findIndex((modalLink) => {
        const match = matchPath(window.location.pathname, {
          path: modalLink,
          exact: true,
          strict: false,
        });
        return match !== null;
      });

      // don't update, it's a modal opened
      if (modalLinkIndex >= 0) {
        // console.log("don't update, it's a modal opened");
        return true;
      } else if (
        // don't update, locations are the same
        previousMainComponentLocation &&
        previousMainComponentLocation.pathname === window.location.pathname
      ) {
        return true;
      } else {
        // update, locations are different
        // console.log(
        //   "update, locations are different",
        //   previousMainComponentLocation
        //     ? previousMainComponentLocation.pathname
        //     : null,
        //   window.location.pathname
        // );
        previousMainComponentLocation = window.location;
        return false;
      }
    } else {
      // if user is logged in, update
      if (!oldProps.user.model && newProps.user.model) {
        // console.log("update, user is logged in");
        return false;
      }

      // don't update, locations are the same
      // console.log("don't update, locations are the same");
      return true;
    }
  }
);

export default withRouter(MainRoutesComponent);
