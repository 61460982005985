/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */

import React, { useCallback, useContext } from "react";
import DesignTooltip from "./DesignTooltip";
import PropTypes from "prop-types";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const TextAreaSetup = (props) => {
  const { title, name, show } = props;
  const { options } = props.control;

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  /**
   *
   * @param field
   * @returns {(function(*): void)|*}
   */
  const onInputValueChange = useCallback(
    (field) => (e) => {
      props.updateDesignProp([
        { field: field + "Option", value: "custom" },
        { field, value: e.target.value },
      ]);
    },
    [props.updateDesignProp]
  );

  if (!show) {
    return null;
  }

  return (
    <div className={"design-element"}>
      <label className="option-label">
        <span className={"option-title"}>{title}</span>
        <DesignTooltip help={props.tooltip} />
      </label>

      <textarea
        disabled={false}
        value={widgetSettingsContext[name]}
        onChange={onInputValueChange(name)}
        type="textarea"
        className="form-control"
        placeholder={options.placeholder}
      />
    </div>
  );
};

TextAreaSetup.propTypes = {
  ...BuilderInputSetupPropTypes,

  control: PropTypes.shape({
    kind: PropTypes.oneOf(["textarea"]).isRequired,
    options: PropTypes.shape({
      placeholder: PropTypes.string,
    }),
  }).isRequired,
};

export default TextAreaSetup;
