/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/10/2024
 */

import React, { useCallback, useEffect } from "react";
import DomainForm from "../Domain/DomainForm";
import { useDomainsStore } from "../../../../hooks/redux/domains";

const AddWebsiteStep = ({ onNextStep }) => {
  const { domains, updateDomain } = useDomainsStore();

  useEffect(() => {
    if (domains.updateSuccess) {
      onNextStep();
    }
  }, [domains.updateSuccess]);

  const onSubmit = useCallback(
    (values) => {
      updateDomain(null, values);
    },
    [onNextStep, updateDomain]
  );

  const cancelAddDomainButton = (
    <button className={"btn btn-link"} type="button" onClick={onNextStep}>
      Skip for now
    </button>
  );

  return (
    <div>
      <div className={"m-b-30"}>
        <p>
          To embed your calendars, provide the domain name to which you would
          like to grant access. You can control which websites can use your
          calendar.
        </p>

        <p>
          Valid website domains examples: mywebsite.com ,
          subdomain.mywebsite.com Do not add any trailing slashes, protocols
          (http://, https://) or www sub domain to your website domain.
        </p>
        <p>
          For testing and development purposes, these domains are always allowed
          to embed the calendars: localhost, localhost:8080, localhost:48081
        </p>
      </div>
      <div>
        <DomainForm
          onSubmit={onSubmit}
          isNew={true}
          cancelButton={cancelAddDomainButton}
          loading={domains.update}
          updateError={domains.updateError}
        />
      </div>
    </div>
  );
};

export default AddWebsiteStep;
