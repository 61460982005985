/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React, { useContext, useMemo, useCallback } from "react";

import PropTypes from "prop-types";
import AsyncCreatable from "react-select/async-creatable";
import axios from "axios";
import AxiosConfig from "../../../../../AxiosConfig";
import DesignTooltip from "./DesignTooltip";
import { useFontsStore } from "../../../../../hooks/redux/fonts";
import { BuilderInputSetupPropTypes } from "./BuilderOptionPropTypes";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

/**
 *
 * @param inputValue
 * @returns {string}
 */
const generateSelectorLabel = (inputValue) => {
  return "Select custom font '" + inputValue + "'";
};

const FontFamilySetup = (props) => {
  const { fonts } = useFontsStore();

  const { name, title, defaultValue } = props;

  const nameOption = name + "Option";
  const inputName = name + "-input";

  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const inputValue = widgetSettingsContext[name] || "default";
  const optionValue = widgetSettingsContext[nameOption] || "default";

  /**
   *
   */
  const _fetchFonts = (input, callback) => {
    if (input && input.length < 1) {
      return callback([]);
    }

    return axios
      .get("/api/fonts/search/" + input + ".json", AxiosConfig.getAuthConfig())

      .then((response) => {
        //this.currentSearchResult = data;
        return callback(
          response.data.map((i) => {
            return { value: i.family, label: i.family };
          })
        );
      });
  };

  /**
   *
   * @param event
   */
  const onValueChange = useCallback(
    (propertyName) => (event) => {
      const value = event ? event.value : "";

      props.updateDesignProp([
        {
          field: propertyName,
          value,
        },
        {
          field: propertyName + "Data",
          value,
        },
      ]);
    },
    [props.updateDesignProp]
  );

  const onOptionChange = useCallback(
    (propertyNameOption, propertyName, defaultValue, kind) => (event) => {
      props.updateDesignProp([
        { field: propertyNameOption, value: kind },
        { field: propertyName, value: defaultValue },
      ]);
    },
    [props.updateDesignProp]
  );

  const isCustom = optionValue === "custom";

  const asyncValue = useMemo(
    () => (isCustom ? [{ value: inputValue, label: inputValue }] : null),
    [isCustom]
  );

  const selectFont = (
    <AsyncCreatable
      className="async-creatable"
      isDisabled={!isCustom}
      isSearchable={true}
      isClearable={true}
      onChange={onValueChange(name)}
      placeholder={"Type Google font name"}
      loadOptions={_fetchFonts}
      formatCreateLabel={generateSelectorLabel}
      value={asyncValue}
    />
  );

  return (
    <div className={"design-element"}>
      <label className="option-label">
        <span className={"option-title"}>{title}</span>
        <DesignTooltip help={props.tooltip} />
      </label>

      <div className="form-group color-picker-row">
        <div className="col-md-12 px-0">
          <div className="radio">
            <label>
              <input
                type="radio"
                name={inputName}
                checked={optionValue === "default"}
                onChange={onOptionChange(
                  nameOption,
                  name,
                  defaultValue,
                  "default"
                )}
              />{" "}
              Default
            </label>
          </div>

          <div className="radio">
            <label>
              <input
                type="radio"
                name={inputName}
                checked={optionValue === "inherit"}
                onChange={onOptionChange(
                  nameOption,
                  name,
                  "inherit",
                  "inherit"
                )}
              />{" "}
              Inherit
            </label>
          </div>

          <div className="radio">
            <label>
              <input
                type="radio"
                name={inputName}
                checked={optionValue === "custom"}
                onChange={onOptionChange(
                  nameOption,
                  name,
                  defaultValue,
                  "custom"
                )}
              />{" "}
              Custom
            </label>
          </div>

          {selectFont}
        </div>
        <div className="col-xs-6" />
      </div>
    </div>
  );
};

FontFamilySetup.propTypes = {
  ...BuilderInputSetupPropTypes,
  control: PropTypes.shape({
    kind: PropTypes.oneOf(["fontFamily"]),
    options: PropTypes.shape({
      placeholder: PropTypes.string,
      inherit: PropTypes.bool,
    }),
  }),
};

export default FontFamilySetup;
