/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/10/2023.
 */

import React, { useContext, useEffect, useMemo } from "react";

import SidebarTab from "../../component/WidgetSettings/SidebarTab";
import MandatoryInformationForm from "./../NewWidgetPageTemplate/MandatoryInformationForm";
import CloneSettings from "./SettingsTabs/CloneSettings";
import FiltersSection from "./SettingsTabs/FiltersSection";
import PropTypes from "prop-types";
import SuccessAlert from "../../component/SuccessAlert";
import ApiError from "../../component/ApiError";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import { useFiltersStore } from "../../../../hooks/redux/filters";
import FormError from "../../component/FormError";
import { useWidgetStore } from "../../../../hooks/redux/widget";
import WidgetContext from "../../contexts/WidgetContext";
import classNames from "classnames";
import IntegrationContext from "../../../../contexts/IntegrationContext";

const SetupTab = (props) => {
  const { widget } = useWidgetStore();
  const { integrations, hasSetupChanged } = props;

  const integrationStrategy = useContext(IntegrationContext);
  const widgetContext = useContext(WidgetContext);
  const { allowedIntegrations } = widgetContext;

  const integrationModel = useMemo(
    () =>
      widget.model
        ? integrations.find((i) => i.uuid === widget.model.integration.uuid)
        : {},
    [integrations, widget.model.integration]
  );

  const { fetchIntegrationFilters, clearFilters } = useFiltersStore();

  useEffect(() => {
    if (integrationStrategy) {
      const filtersToFetch = integrationStrategy._filters
        .map((i) => {
          if (i.fetch === undefined || i.fetch === true) {
            return i.field.value;
          }
          return null;
        })
        .filter((i) => i !== null);

      fetchIntegrationFilters(
        widget.model.integration.uuid,
        filtersToFetch,
        widget.model.integration.type
      );
    }

    return () => {
      clearFilters();
    };
  }, [integrationModel, integrationStrategy]);

  const cloneSettings = (
    <CloneSettings
      overwriteDesignProp={props.overwriteDesignProp}
      overwriteFilterProp={props.overwriteFilterProp}
    />
  );

  const widgetSpecificFilters = widgetContext.filters || null;

  const eventsFiltering =
    widget.model && widget.model.uuid && integrationModel ? (
      <FiltersSection
        user={props.user}
        widgetModel={props.widgetModel || {}}
        onUpdateField={props.onUpdateField}
        disabled={props.disabled}
        integrationModel={integrationModel}
        formErrors={props.errors}
        onUpdateFilterSettings={props.onUpdateFilterSettings}
      />
    ) : null;

  const footer = !props.disabled ? (
    <div>
      <SuccessAlert>
        {widget.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>

      {Object.keys(props.errors).length ? (
        <FormError errors={Object.values(props.errors)} />
      ) : null}
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      {!widget.updateSuccess && !widget.updateError ? (
        <div
          className={classNames("alert d-flex m-0 gap-xxs p-2", {
            "alert-warning": hasSetupChanged,
            "justify-content-end": !hasSetupChanged,
          })}
        >
          {hasSetupChanged ? (
            <div className="text-left">
              Setup changes will appear visible after saving them.
            </div>
          ) : null}

          <button
            disabled={props.disabled}
            type="submit"
            className="btn btn-primary"
            onClick={props.onSubmit}
          >
            Update
          </button>
        </div>
      ) : null}
    </div>
  ) : (
    <LoadingIndicator />
  );

  const additionalContent = widgetContext.setupTabComponents.map(
    (Component, index) => (
      <Component
        key={index}
        widgetType={widget.model.widget_type}
        widgetModel={props.widgetModel || {}}
        onUpdateField={props.onUpdateField}
        onUpdateWidgetSettings={props.onUpdateWidgetSettings}
        disabled={props.disabled}
        formErrors={props.errors}
      />
    )
  );

  return (
    <SidebarTab id="setup_tab" show={true} footer={footer}>
      {widgetSpecificFilters}
      {eventsFiltering}
      {additionalContent}
      {cloneSettings}
      <MandatoryInformationForm
        widgetType={widget.model.widget_type}
        widgetModel={props.widgetModel || {}}
        integrations={integrations}
        onUpdateField={props.onUpdateField}
        disabled={props.disabled}
        formErrors={props.errors}
        allowedIntegrations={allowedIntegrations}
        showSummary={true}
      />

      {/*{featuredFiltering}*/}
    </SidebarTab>
  );
};

SetupTab.propTypes = {
  // form fields and errors
  widgetModel: PropTypes.object,
  errors: PropTypes.object,

  // for event filters
  widget: PropTypes.object,

  // for mandatory information and events filtering
  integrations: PropTypes.array,

  // whether diable form
  disabled: PropTypes.bool,

  onSubmit: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateFilterSettings: PropTypes.func.isRequired,
  onUpdateWidgetSettings: PropTypes.func.isRequired,

  // whether to show events filtering/featured events
  isNew: PropTypes.bool,

  // for cloning
  overwriteDesignProp: PropTypes.func,
  overwriteFilterProp: PropTypes.func,

  // to determine user's plan
  user: PropTypes.object,

  // whether setup has changed
  hasSetupChanged: PropTypes.bool,
};

export default SetupTab;
