/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022.
 */
import FilterLabelCTA from "./CCBFilteringStrategy/FilterLabelCTA";
import { IT, PLANS } from "../../../consts";
import SignupsIntegrationStrategy from "./SignupsIntegrationStrategy";
import { BooleanOptions } from "./filterOptions";

class CCBStrategy extends SignupsIntegrationStrategy {
  type = IT.ccb;

  availableFilters = [
    "status",
    "area",
    "campus",
    "department",
    "meeting_day",
    "meeting_time",
    "childcare",
    "public_search_listed",
    "registration_status",
  ];

  filtersSettings = {
    status: {
      label: "Status",
      tooltip: null,
    },
  };

  filterLabelCTA = FilterLabelCTA();

  /**
   *
   * @param data
   * @returns {{}}
   */
  filterRawDataToStateObject = (data) => {
    const filterObjects = this.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: [] }),
      {}
    );

    data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      filterObjects[filter.kind].push(filter);
    });

    return filterObjects;
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  filterRawToDisplayObject = (data, showId) => {
    return data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(
        { ...filter.data, ...filter },
        showId
      );
      return filter;
    });
  };

  /**
   *
   * @param data
   * @param showInactive
   * @returns {*}
   */
  filterRenderName = (filter, showId = false) => {
    let name = filter.data.name;

    if (showId) {
      name +=
        (filter.platform_metadata_id
          ? " [" + filter.platform_metadata_id + "]"
          : "") +
        (filter.data.inactive && filter.data.inactive === "true"
          ? " (inactive)"
          : "");
    }

    return name;
  };

  _filters = [
    {
      field: {
        value: "status",
        label: "Status",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: [
        {
          id: "available",
          platform_metadata_id: "available",
          value: "Available",
          label: "Published",
          name: "Published",
          kind: "status",
        },
        {
          id: "unpublished",
          platform_metadata_id: "unpublished",
          value: "Unpublished",
          label: "Unpublished",
          name: "Unpublished",
          kind: "status",
        },
        {
          id: "scheduled",
          platform_metadata_id: "scheduled",
          value: "Scheduled",
          label: "Scheduled",
          name: "Scheduled",
          kind: "status",
        },
        {
          id: "expired",
          platform_metadata_id: "expired",
          value: "Expired",
          label: "Expired",
          name: "Expired",
          kind: "status",
        },
        {
          id: "disabled",
          platform_metadata_id: "disabled",
          value: "Disabled",
          label: "Disabled",
          name: "Disabled",
          kind: "status",
        },
      ],
      allowedOperators: ["is", "not"],
      allowedValues: ["published", "unpublished", "scheduled", "expired"],
      fetch: false,
    },
    {
      field: {
        value: "campus",
        label: "Campus",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "public",
        label: "Show public only",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: BooleanOptions,
      allowedOperators: ["is"],
      allowedValues: ["true", "false"],
      fetch: false,
    },
  ];
}

export default CCBStrategy;
