/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/10/2024
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { WT } from "../consts/widget";
import AddWebsiteStep from "./WidgetInstallModal/AddWebsiteStep";
import { useDomainsStore } from "../../../hooks/redux/domains";
import Wizard from "./WidgetInstallModal/Wizard";
import { Platforms, PlatformsLabels } from "./WidgetInstallModal/consts";

import StandardInstall from "./WidgetInstallModal/StandardInstall";
import WordPressInstructions from "./WidgetInstallModal/common/instructions/WordPressInstructions";
import WIXInstructions from "./WidgetInstallModal/common/instructions/WIXInstructions";
import NucleusInstructions from "./WidgetInstallModal/common/instructions/NucleusInstructions";
import SquareSpaceInstructions from "./WidgetInstallModal/common/instructions/SquareSpaceInstructions";
import ChurchCoInstructions from "./WidgetInstallModal/common/instructions/ChurchCoInstructions";

import EventAlertsStandardInstall from "./WidgetInstallModal/alerts/instructions/StandardInstall";
import EventAlertsWordPressInstructions from "./WidgetInstallModal/alerts/instructions/WordPressInstructions";
import EventAlertsWIXInstructions from "./WidgetInstallModal/alerts/instructions/WIXInstructions";
import EventAlertsNucleusInstructions from "./WidgetInstallModal/alerts/instructions/NucleusInstructions";
import EventAlertsSquareSpaceInstructions from "./WidgetInstallModal/alerts/instructions/SquareSpaceInstructions";
import EventAlertsChurchCoInstructions from "./WidgetInstallModal/alerts/instructions/ChurchCoInstructions";

import sleep from "../../../helpers/sleep";
import SelectPlatform from "./WidgetInstallModal/SelectPlatform";
import MailchimpInstructions from "./WidgetInstallModal/calendar/instructions/MailchimpInstructions";

const StepLabels = {
  1: "Add website",
  2: "Select platform",
  3: "Install widget",
};

const InstructionsPerWidgetPlatform = {
  [WT.calendar]: {
    [Platforms.standard]: StandardInstall,
    [Platforms.wordpress]: WordPressInstructions,
    [Platforms.wix]: WIXInstructions,
    [Platforms.nucleus]: NucleusInstructions,
    [Platforms.squarespace]: SquareSpaceInstructions,
    [Platforms.churchco]: ChurchCoInstructions,
    [Platforms.mailchimp]: MailchimpInstructions,
  },
  [WT.groups]: {
    [Platforms.standard]: StandardInstall,
    [Platforms.wordpress]: WordPressInstructions,
    [Platforms.wix]: WIXInstructions,
    [Platforms.nucleus]: NucleusInstructions,
    [Platforms.squarespace]: SquareSpaceInstructions,
    [Platforms.churchco]: ChurchCoInstructions,
  },
  [WT.alerts]: {
    [Platforms.standard]: EventAlertsStandardInstall,
    [Platforms.wordpress]: EventAlertsWordPressInstructions,
    [Platforms.wix]: EventAlertsWIXInstructions,
    [Platforms.nucleus]: EventAlertsNucleusInstructions,
    [Platforms.squarespace]: EventAlertsSquareSpaceInstructions,
    [Platforms.churchco]: EventAlertsChurchCoInstructions,
  },
  [WT.signups]: {
    [Platforms.standard]: StandardInstall,
    [Platforms.wordpress]: WordPressInstructions,
    [Platforms.wix]: WIXInstructions,
    [Platforms.nucleus]: NucleusInstructions,
    [Platforms.squarespace]: SquareSpaceInstructions,
    [Platforms.churchco]: ChurchCoInstructions,
  },
};

const WidgetInstallModal = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onClose,
}) => {
  const [modalDom] = useState(document.createElement("div"));
  const [VFXState, setVFXState] = useState({
    showWrapper: false,
    showContainer: false,
  });

  const { domains } = useDomainsStore();

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPlatform, setSelectedPlatform] = useState("");

  /**
   *
   * @param setState
   * @param state
   * @returns {Promise<void>}
   */
  const setStatePromise = (setState, state) => {
    setState(state);
    return Promise.resolve();
  };

  useEffect(() => {
    document.body.appendChild(modalDom);

    if (domains.collection.length) {
      setCurrentStep(2);
    }

    return () => document.body.removeChild(modalDom);
  }, []);

  /**
   *
   * @param e
   */
  const onModalAreaClick = (e) => {
    const eventContainer = document.querySelector("#install-modal-container");

    if (!eventContainer.contains(e.target)) {
      e.stopPropagation();
      onCloseClick();
    }
  };

  /**
   *
   * @type {(function(): void)|*}
   */
  const onCloseClick = useCallback(() => {
    setStatePromise(setVFXState, {
      showWrapper: false,
      showContainer: false,
    })
      .then(() => sleep(150))
      .then(() => {
        document.body.style.overflow = "auto";
        onClose();
      });
  }, [VFXState]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onNextClick = useCallback(() => {
    setCurrentStep((prev) => prev + 1);
  }, [currentStep, selectedPlatform]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onPreviousClick = useCallback(() => {
    setCurrentStep((prev) => prev - 1);
  }, [currentStep, selectedPlatform]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onGoToAddWebsite = useCallback(
    (e) => {
      e.preventDefault();
      setCurrentStep(1);
    },
    [currentStep]
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onSelectedPlatform = useCallback(
    (platform) => {
      setSelectedPlatform(platform);
    },
    [currentStep, selectedPlatform]
  );

  const currentStepSubHeader = useMemo(() => {
    // StepLabels[currentStep]
    if (currentStep === 3) {
      return `${StepLabels[currentStep]} on ${PlatformsLabels[selectedPlatform]}`;
    }
    return StepLabels[currentStep];
  }, [currentStep, selectedPlatform]);

  const footer = useMemo(() => {
    switch (currentStep) {
      case 1:
        return null;

      case 2:
        return (
          <div className={"install-modal--footer"}>
            <button className={"btn btn-primary"} onClick={onNextClick}>
              Next
            </button>
          </div>
        );

      case 3:
        return (
          <div className={"install-modal--footer"}>
            <button
              className={"btn btn-outline-primary"}
              onClick={onPreviousClick}
            >
              Back
            </button>

            <button className={"btn btn-primary"} onClick={onCloseClick}>
              Finish
            </button>
          </div>
        );

      default:
        return null;
    }
  }, [currentStep, onNextClick]);

  const form = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <AddWebsiteStep onNextStep={onNextClick} />;
      case 2:
        return (
          <SelectPlatform
            onSelectedPlatform={onSelectedPlatform}
            selectedPlatform={selectedPlatform}
            onGoToAddWebsite={onGoToAddWebsite}
            widgetType={widgetType}
          />
        );
      case 3:
        // ...
        const SelectedPlatformInstructionsComponent =
          InstructionsPerWidgetPlatform[widgetType][selectedPlatform];

        return (
          <SelectedPlatformInstructionsComponent
            uuid={uuid}
            slug={slug}
            widgetType={widgetType}
            widgetTypePrintable={widgetTypePrintable}
            onGoToAddWebsite={onGoToAddWebsite}
          />
        );

      default:
        return null;
    }
    // ...
  }, [currentStep, onNextClick, selectedPlatform, onSelectedPlatform]);

  return ReactDOM.createPortal(
    <div
      className={classnames("install-modal--wrapper", uuid, {
        show: VFXState.showWrapper,
      })}
      id="install-modal-wrapper"
      onClick={onModalAreaClick}
    >
      <div
        className={classnames("install-modal--container", {
          show: VFXState.showContainer,
        })}
        id={"install-modal-container"}
      >
        <div className={"install-modal--close-wrapper"} onClick={onCloseClick}>
          <div className={"install-modal--close"}>
            <i className={"material-icons"}>close</i>
          </div>
        </div>
        <div className={"install-modal--header"}>
          <h3>Install {widgetType} widget</h3>
          <Wizard currentStep={currentStep} />
          <h4 className={"text-secondary"}>{currentStepSubHeader}</h4>
        </div>

        <div className={"install-modal-content"}>{form}</div>
        {footer}
      </div>
    </div>,
    modalDom
  );
};

export default WidgetInstallModal;
