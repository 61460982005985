/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2024
 */

import React from "react";
import WebsiteMissingWarning from "./WebsiteMissingWarning";

const SelectPlatformTemplate = ({ onGoToAddWebsite, children }) => {
  return (
    <div>
      <div className={"d-flex w-100 justify-content-center my-4"}>
        <WebsiteMissingWarning onAddWebsite={onGoToAddWebsite} />
      </div>
      <hr />
      <div className={"pt-4"}>{children}</div>
    </div>
  );
};

export default SelectPlatformTemplate;
