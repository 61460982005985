import { useEffect, useMemo } from "react";

const useHeaderFilters = (
  integrationStrategy,
  widgetSettings,
  fetch,
  uuid,
  shouldFetch,
  deps
) => {
  const allowedFilterKinds = useMemo(() => {
    const filterSettings = integrationStrategy._filters.reduce(
      (acc, filter) => {
        acc[filter.field.value] = filter;
        return acc;
      }
    );

    return integrationStrategy.availableFilters
      .map((kind) => {
        const showSelector = widgetSettings["show" + kind + "Dropdown"];

        if (!showSelector) {
          return null;
        }

        const additionalConfig = filterSettings[kind];

        // TODO
        if (
          additionalConfig &&
          additionalConfig.fetch !== undefined &&
          additionalConfig.fetch === false
        ) {
          return null;
        }

        return kind;
      })
      .filter((i) => i)
      .join(",");
  }, [integrationStrategy.availableFilters, widgetSettings]);

  useEffect(() => {
    if (shouldFetch) {
      //console.log("fetching filters");
      fetch(
        uuid,
        "widget_filters",
        allowedFilterKinds,
        null,
        integrationStrategy.type
      );
    }
  }, deps);
};

export default useHeaderFilters;
