import { getMailchimpSnippet, getPreview } from "./listTemplate";
import { getRssFeedUrl } from "./helpers";

/**
 *
 * @param settings
 * @param headerTemplate
 * @param itemTemplate
 * @returns {`
 ${string}
 *|FEEDBLOCK:${string}|*
 *|FEEDITEMS:[${string|string}$constrain_rss_img=Y]|*
 ${string}
 *|END:FEEDITEMS|*
 *|END:FEEDBLOCK|*
 `}
 */
const generateMCTemplate = (uuid, settings, headerTemplate, itemTemplate) => {
  const url = getRssFeedUrl(uuid, settings);
  const itemBlock = itemTemplate(settings);
  const headerBlock = headerTemplate(settings);
  const itemsLimit =
    settings?.eventsLimitOption === "upcoming-limit"
      ? `$count=${settings.eventsLimit}, `
      : "";

  return `
    ${headerBlock}
    *|FEEDBLOCK:${url}|*
    *|FEEDITEMS:[${itemsLimit}$constrain_rss_img=Y]|*
          ${itemBlock}
      *|END:FEEDITEMS|*
    *|END:FEEDBLOCK|*
  `;
};

export const getHTMLPreview = (
  headerTemplate,
  itemTemplate,
  settings,
  events
) => {
  const itemHTML = itemTemplate(settings);

  const showAllEvents =
    settings.eventsLimit === "all" || settings.eventsLimitOption === "all";
  const items = events
    .filter((e, idx) => showAllEvents || idx < settings.eventsLimit)
    .map((event) => {
      return itemHTML
        .replaceAll(
          "*|FEEDITEM:IMAGE|*",
          `<img src="${event.imgUrl}" class="mc-rss-item-img" width="100%" height="auto" />`
        )
        .replaceAll("*|FEEDITEM:URL|*", event.link)
        .replaceAll("*|FEEDITEM:TITLE|*", event.title)
        .replaceAll("*|FEEDITEM:CONTENT|*", event.description)
        .replaceAll("*|FEEDITEM:DATE|*", event.startDate)
        .replaceAll("*|FEEDITEM:CATEGORIES|*", event.location);
    })
    .join("");

  return `
  ${headerTemplate(settings)}
  ${items}
  `;
};

const useMailchimpTemplate = (
  uuid,
  headerTemplate,
  itemTemplate,
  settings,
  events
) => {
  /**
   * 
   * @param settings
   * @returns {`
 ${string}
|FEEDBLOCK:${string}|*
|FEEDITEMS:[${string}$constrain_rss_img=Y]|*
 ${string}
|END:FEEDITEMS|*
|END:FEEDBLOCK|*
 `}
   */
  const getMailchimpSnippet = () => {
    return generateMCTemplate(uuid, settings, headerTemplate, itemTemplate);
  };

  const getPreview = () => {
    return getHTMLPreview(headerTemplate, itemTemplate, settings, events);
  };

  return {
    getPreview,
    getMailchimpSnippet,
  };
};

export default useMailchimpTemplate;
