/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/02/2023.
 */

import React, { useCallback, useContext, useEffect } from "react";

import SidebarTab from "../../component/WidgetSettings/SidebarTab";
import MandatoryInformation from "./SettingsTabs/MandatoryInformation";
import PropTypes from "prop-types";
import SuccessAlert from "../../component/SuccessAlert";
import ApiError from "../../component/ApiError";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import Filtering from "./SettingsTabs/Filtering";
import FormError from "../../component/FormError";
import PageSetup from "./InstallTab/PageSetup";
import { useFiltersStore } from "../../../../hooks/redux/filters";
import IntegrationContext from "../../../alerts/strategies/IntegrationContext";
import classNames from "classnames";

const SetupTab = (props) => {
  const { alert, integrations, hasSetupChanged } = props;
  const integrationStrategy = useContext(IntegrationContext);

  const integrationModel = alert.model
    ? integrations.find((i) => i.uuid === alert.model.integration.uuid)
    : {};

  const { fetchIntegrationFilters, clearFilters } = useFiltersStore();

  useEffect(() => {
    fetchIntegrationFilters(
      alert.model.integration.uuid,
      integrationStrategy.availableFilters,
      alert.model.integration.type
    );

    return () => {
      clearFilters();
    };
  }, [integrationModel, integrationStrategy]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onUpdateFilters = useCallback(
    (filters) => {
      props.onUpdateFilterSettings("filters", filters);
    },
    [props.alertModel]
  );

  const filtering =
    alert.model && alert.model.uuid && integrationModel ? (
      <Filtering
        widget={alert}
        widgetModel={props.alertModel || {}}
        onUpdateField={props.onUpdateField}
        disabled={props.disabled}
        integrationModel={integrationModel}
        formErrors={props.errors}
        onUpdateFilters={onUpdateFilters}
      />
    ) : null;

  /*
    const cloneSettings =
      alert.model && alert.model.uuid && props.alerts.length > 1 ? (
        <CloneSettings
          alert={alert}
          alerts={props.alerts}
          onSubmit={props.onSubmit}
          overwriteDesignProp={props.overwriteDesignProp}
        />
      ) : null;


  */

  const footer = !props.disabled ? (
    <div>
      <SuccessAlert>
        {alert.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <ApiError
        error={alert.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      {Object.keys(props.errors).length ? (
        <FormError errors={Object.values(props.errors)} />
      ) : null}

      {!alert.updateSuccess && !alert.updateError ? (
        <div
          className={classNames("alert d-flex m-0 gap-xxs p-2", {
            "alert-warning": hasSetupChanged,
            "justify-content-end": !hasSetupChanged,
          })}
        >
          {hasSetupChanged ? (
            <div className="text-left">
              Setup changes will appear visible after saving them.
            </div>
          ) : null}

          <button
            disabled={props.disabled}
            type="submit"
            className="btn btn-primary"
            onClick={props.onSubmit}
          >
            Update
          </button>
        </div>
      ) : null}
    </div>
  ) : (
    <LoadingIndicator />
  );

  const websitesToDisplay = <PageSetup />;

  return (
    <SidebarTab id="setup_tab" show={true} footer={footer}>
      {websitesToDisplay}
      {/*{cloneSettings}*/}
      {filtering}
      <MandatoryInformation
        alertModel={props.alertModel || {}}
        integrations={integrations}
        onUpdateField={props.onUpdateField}
        disabled={props.disabled}
        formErrors={props.errors}
      />
    </SidebarTab>
  );
};

SetupTab.propTypes = {
  // form fields and errors
  alertModel: PropTypes.object,
  errors: PropTypes.object,

  // for event filters
  alert: PropTypes.object,

  // for cloning
  alerts: PropTypes.array,

  // for mandatory information and items filtering
  integrations: PropTypes.array,

  // whether diable form
  disabled: PropTypes.bool,

  onSubmit: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,

  // whether to show events filtering/featured events
  isNew: PropTypes.bool,

  // for cloning
  overwriteDesignProp: PropTypes.func,

  onUpdateFilterSettings: PropTypes.func.isRequired,
};

export default SetupTab;
