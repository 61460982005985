/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/11/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as filtersActions from "../../actions/filters";
import { fetchGroupFilters } from "../../actions/filters";

export function useFiltersStore() {
  const dispatch = useDispatch();
  const _filters = useSelector((store) => store.filters);

  const fetchIntegrationFilters = useCallback(
    async (integrationUUID, kind, integrationType) =>
      await dispatch(
        filtersActions.fetchIntegrationFilters(
          integrationUUID,
          kind,
          integrationType
        )
      ),
    [dispatch]
  );
  const fetchCalendarFilters = useCallback(
    async (calendarUUID, type, kind, q, integrationType) =>
      await dispatch(
        filtersActions.fetchCalendarFilters(
          calendarUUID,
          type,
          kind,
          q,
          integrationType
        )
      ),
    [dispatch]
  );

  const fetchGroupFilters = useCallback(
    async (widgetUUID, type, kind, q, integrationType) =>
      await dispatch(
        filtersActions.fetchGroupFilters(
          widgetUUID,
          type,
          kind,
          q,
          integrationType
        )
      ),
    [dispatch]
  );

  const clearFilters = useCallback(
    async () => await dispatch(filtersActions.clearFilters()),
    [dispatch]
  );

  return {
    filters: _filters,
    fetchIntegrationFilters,
    fetchCalendarFilters,
    fetchGroupFilters,
    clearFilters,
  };
}
