/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React, { useCallback, useState } from "react";

import { Link } from "react-router-dom";
import SidebarTab from "./SidebarTab";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import SuccessAlert from "../SuccessAlert";
import PropTypes from "prop-types";
import ApiError from "../ApiError";
import { calendarFeaturesCheck, groupFeaturesCheck } from "../../tools";
import { useCalendarsStore } from "../../../../hooks/redux/calendars";
import { PLAN_NAMES } from "../../../../consts";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import Accordion from "./Acordion";
import RadioInput from "./RadioInput";
import ShareableUrl from "./ShareableUrl";
import classNames from "classnames";
import WidgetInstallModal from "../../modals/WidgetInstallModal";
import QRCode from "./QRCode";

const InstallTab = ({ widgetTypeName = "Widget", ...props }) => {
  const { widgetModel, widget } = props;
  const widgetType = widget.model.widget_type;
  const { currentOrganization } = useCurrentOrganizationStore();
  const { calendars } = useCalendarsStore();
  const [showInstallModal, setShowInstallModal] = useState(false);

  const featureCheck =
    widgetModel && widgetModel.integration && !widget.update
      ? widgetType === "calendar"
        ? calendarFeaturesCheck
        : groupFeaturesCheck
      : null;

  const publishIssues = featureCheck
    ? featureCheck(
        { ...widgetModel, available: 1 },
        currentOrganization,
        calendars
      )
    : [];

  const isPublishDisabled =
    widget.update || (!widget.model.available && publishIssues.length)
      ? true
      : false;

  const publishRestrictionWarning =
    !widgetModel.available && publishIssues.length ? (
      <div className={"alert alert-danger mt-3 p-2"}>
        <i className={"text-danger m-r-5 material-icons icon-sm"}>warning</i>
        You cannot publish this {widgetType} widget because:
        <ul className={"pl-3 "}>
          {publishIssues.map((i, idx) => (
            <li key={`cv-${idx}`}>{i}</li>
          ))}
        </ul>
        {props.recommendedPlan && (
          <span>
            Click here to upgrade to the minimum required plan{" "}
            <strong>{PLAN_NAMES[props.recommendedPlan]}</strong>.
          </span>
        )}
        <div className={"d-flex justify-content-center"}>
          <Link to={"/billing"}>
            <button className={"btn btn-success btn-sm"}>Upgrade now</button>
          </Link>
        </div>
      </div>
    ) : null;

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChangeStatus = useCallback(
    (field) => (e) => {
      props.onUpdateStatus(!(widget.model.available * 1) ? 1 : 0);
    },
    [props.onUpdateStatus, widget]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onChangeStatusButton = useCallback(
    (e) => {
      onChangeStatus("available")(e);
    },
    [onChangeStatus]
  );

  const onShowInstallModal = useCallback(() => {
    setShowInstallModal(true);
  }, [setShowInstallModal]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onCloseInstallModal = useCallback(() => {
    setShowInstallModal(false);
  }, [setShowInstallModal]);

  const widgetTypeNamePrintable = widgetTypeName.toLowerCase();

  const installButton =
    widgetModel.available && !publishIssues.length ? (
      <div className={"d-flex flex-column"}>
        <div className={"my-2"}>
          <button
            className={classNames("btn btn-primary w-100", {
              disabled: props.disabled || !widgetModel.available,
            })}
            disabled={props.disabled || !widgetModel.available}
            onClick={onShowInstallModal}
          >
            Install
          </button>
        </div>
      </div>
    ) : null;

  const publishCard = (
    <Accordion
      title={"Publish"}
      subtitle={`Your ${widgetTypeNamePrintable} is ${
        widget.model.available * 1 == 0 ? "is not" : ""
      } published.`}
      keepExpanded={true}
    >
      <RadioInput
        fieldName={"available"}
        label={"Make this widget available to others"}
        onChange={onChangeStatus}
        checked={widgetModel.available}
        disabled={isPublishDisabled}
        tooltip={
          widget.model.available * 1 === 0
            ? {
                message: `In order to share your ${widgetTypeNamePrintable} and make it accessible by
              others, change it status to published. You can make it here using
              the toggle button.`,
              }
            : null
        }
      />
      {publishRestrictionWarning}
      {installButton}

      <SuccessAlert className={"p-2"}>
        {widget.updateSuccess ? "Changes have been saved!" : null}
      </SuccessAlert>
    </Accordion>
  );

  const unpublishedWarning = !widgetModel.available ? (
    <div
      className={
        "alert alert-warning fade show d-flex align-items-center flex-column p-2"
      }
    >
      This widget is unpublished. Publish your widget first.
      <button
        className="btn btn-primary btn-sm m-2"
        onClick={onChangeStatusButton}
      >
        Publish
      </button>
    </div>
  ) : null;

  const installModal = showInstallModal ? (
    <WidgetInstallModal
      widgetType={widgetType}
      uuid={widget.model.uuid}
      show={showInstallModal}
      onClose={onCloseInstallModal}
      widgetTypePrintable={widgetTypeNamePrintable}
    />
  ) : null;

  const footer = !props.disabled ? (
    <div>
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
    </div>
  ) : (
    <LoadingIndicator />
  );

  return (
    <SidebarTab id="install_tab" show={false} footer={footer}>
      {publishCard}

      {widget.model.available && props.shareableUrl ? (
        <>
          <Accordion
            title={"Hosted Link and Redirection"}
            subtitle={
              "Copy the widget-hosted Link and/or set the destination for navigation."
            }
          >
            <ShareableUrl
              url={props.shareableUrl}
              widgetModel={widgetModel}
              widgetTypeName={widgetTypeNamePrintable}
              onUpdateSettings={props.onUpdateWidgetSettings}
              onSubmit={props.onSubmit}
            />
          </Accordion>
          <Accordion
            title={"QR code"}
            subtitle={
              "Download your QR Code. Utilize it in your posters and social media promotions to enhance visibility."
            }
          >
            <QRCode url={props.shareableUrl} title={widgetModel.name} />
          </Accordion>
        </>
      ) : null}

      <div className={"mx-2"}>{unpublishedWarning}</div>
      {installModal}
    </SidebarTab>
  );
};

InstallTab.propTypes = {
  // form fields and errors
  widget: PropTypes.object.isRequired,
  widgetModel: PropTypes.object,

  errors: PropTypes.object,

  onSubmit: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,

  isNew: PropTypes.bool,
  hasDomains: PropTypes.bool,

  disabled: PropTypes.bool,

  widgetTypeName: PropTypes.string,
  widgetType: PropTypes.string,
  shareableUrl: PropTypes.string,

  recommendedPlan: PropTypes.string,
  onUpdateWidgetSettings: PropTypes.func,
};

export default InstallTab;
