/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/10/2024
 */

import React, { useCallback } from "react";
import PlatformSelectorButton from "./PlatformSelectorButton";
import SelectPlatformTemplate from "./SelectPlatformTemplate";
import appConfig from "../../../../appConfig";
import { Platforms as PTypes, PlatformsLabels } from "./consts";
import { WT } from "../../consts/widget";

const Platforms = [
  {
    id: PTypes.wordpress,
    icon: "wordpress.png",
    label: PlatformsLabels[PTypes.wordpress],
  },
  {
    id: PTypes.wix,
    icon: "wix.png",
    label: PlatformsLabels[PTypes.wix],
  },
  {
    id: PTypes.nucleus,
    icon: "nucleus.png",
    label: PlatformsLabels[PTypes.nucleus],
    availableOnlyFor: ["dc"],
  },
  {
    id: PTypes.squarespace,
    icon: "squarespace.png",
    label: PlatformsLabels[PTypes.squarespace],
  },
  {
    id: PTypes.churchco,
    icon: "churchco.png",
    label: PlatformsLabels[PTypes.churchco],
    availableOnlyFor: ["dc"],
  },
  {
    id: PTypes.mailchimp,
    icon: "mailchimp.png",
    label: PlatformsLabels[PTypes.mailchimp],
    applicableWidgetTypes: [WT.calendar],
  },
];
export const SelectPlatform = ({
  onGoToAddWebsite,
  selectedPlatform,
  onSelectedPlatform,
  widgetType,
}) => {
  /**
   *
   */
  const onPlatformSelect = useCallback(
    (platformId) => {
      onSelectedPlatform(platformId);
    },
    [selectedPlatform]
  );

  const mainPlatformsButtons = Platforms.filter(
    (i) =>
      !i.availableOnlyFor || i.availableOnlyFor.includes(appConfig.branding)
  )
    .filter(
      (i) =>
        !i.applicableWidgetTypes || i.applicableWidgetTypes.includes(widgetType)
    )
    .map((platform) => (
      <PlatformSelectorButton
        key={platform.id}
        id={platform.id}
        icon={platform.icon}
        label={platform.label}
        selected={selectedPlatform === platform.id}
        onSelect={onPlatformSelect}
      />
    ));

  return (
    <SelectPlatformTemplate onGoToAddWebsite={onGoToAddWebsite}>
      <div className={"install-modal--platforms-selection-container"}>
        {mainPlatformsButtons}
      </div>
      <hr />
      <div className={"pt-4"}>
        <p className={"text-center"}>
          Don't see your platform? Use this general option that works for most
          websites.
        </p>
        <div className={"d-flex w-100 justify-content-center"}>
          <PlatformSelectorButton
            id={"standard"}
            icon={"standard_install.png"}
            label={"Standard install"}
            selected={selectedPlatform === "standard"}
            onSelect={onPlatformSelect}
          />
        </div>
      </div>
    </SelectPlatformTemplate>
  );
};

export default SelectPlatform;
