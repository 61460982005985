/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/10/2024
 */

import React, { useMemo } from "react";
import WebsiteMissingWarning from "../../WebsiteMissingWarning";
import WebsitesVerifiedInfo from "../../WebsiteVerifiedInfo";
import InstallAccordion from "../../InstallAccordion";
import ClipboardCopyInput from "../../../../component/ClipboardCopyInput";
import FeatureFlag from "../../../../component/FeatureFlag";
import {
  getWixEmbeddedScript,
  wixHeadScript,
} from "../../../../templates/WidgetPageTemplate/InstallTab/helpers";
import appConfig from "../../../../../../appConfig";

const WordPressInstructions = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onGoToAddWebsite,
}) => {
  const installDiv = useMemo(
    () => getWixEmbeddedScript(widgetType, uuid),
    [widgetType, uuid]
  );

  return (
    <div>
      <FeatureFlag name={"wix-embeddable"}>
        <div className={"w-100 d-flex align-items-center flex-column"}>
          <p className={"text-center"}>
            Watch our tutorial video below or{" "}
            <a
              target={"_blankWIXInstructions"}
              href={
                "https://help.display.church/en/articles/6889536-wix-embedding-your-view"
              }
            >
              Read our Article
            </a>
            .
          </p>
          <div className={"install-modal--video-tutorial"}>
            <div>
              <iframe
                src="https://player.vimeo.com/video/788909531?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                title="Embedding into Wordpress"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </FeatureFlag>

      <div className={"my-4"}>
        <WebsiteMissingWarning onAddWebsite={onGoToAddWebsite} />
        <WebsitesVerifiedInfo />
      </div>
      <InstallAccordion title={`Installing ${appConfig.displayName} on WIX`}>
        <ol>
          <li>
            In your Wix dashboard, click on <strong>Settings</strong>.
          </li>
          <li>
            Within the Settings menu, scroll down to the Advanced section and
            click on <strong>Custom Code</strong>.
          </li>
          <li>
            In the Head section, click on <strong>Add Code</strong>.
          </li>
          <li>
            Paste the following snipped in the{" "}
            <span className={"text-purple"}>code</span> section.
            <ClipboardCopyInput
              id={"embeddable_script"}
              value={wixHeadScript}
              placeholder={`${widgetTypePrintable} embeddable script`}
            />
          </li>
          <li>Give this code a memorable name.</li>
          <li>
            You may tailor the remaining settings, however, default options are
            fine enough.
          </li>
          <li>
            In <span className={"text-purple"}>Code Type</span> tab, mark code
            as <string>Essential</string>.
          </li>
        </ol>
        <p>
          <strong>Important!</strong> You only have to do the above part once,
          regardless of the number of widgets you embed. In other words, if you
          embed more than one widget, in this same website, you do not need to
          repeat this operation.
        </p>
      </InstallAccordion>
      <InstallAccordion
        title={`Adding ${widgetTypePrintable} to selected page`}
      >
        <ol>
          <li>
            Edit the page on which you want to display the {widgetTypePrintable}
            .
          </li>
          <li>
            From <strong>Add elements</strong> menu, drag and drop the{" "}
            <strong>Embed Code</strong> block into the page content section.
          </li>
          <li>
            From the embed options, choose <strong>Embed HTML</strong>.
          </li>
          <li>
            Select <strong>Code</strong> option when{" "}
            <span className={"text-purple"}>What do you want to add?</span>{" "}
          </li>
          <li>
            In the <strong>Add your code here</strong> box, paste the following
            code snippet.
            <ClipboardCopyInput
              id={"embeddable_div"}
              value={installDiv}
              placeholder={`${widgetTypePrintable} embeddable div`}
              tag={"textarea"}
              rows={5}
            />
          </li>
          <li>
            You may wish to adjust the element size, so it fits nicely on your
            page.
          </li>
        </ol>
      </InstallAccordion>
    </div>
  );
};

export default WordPressInstructions;
