/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/04/2022.
 */
// import PrivateEventsSyncWarning from "./PCOFilteringStrategy/PrivateEventsSyncWarning";
import FilterLabelCTA from "./PCOFilteringStrategy/FilterLabelCTA";
import { IT, PLANS } from "../../../consts";
import GroupsIntegrationStrategy from "./GroupsIntegrationStrategy";

class PCOStrategy extends GroupsIntegrationStrategy {
  type = IT.pco;

  availableFilters = [
    "group_type",
    "location",
    "group_status",
    "group_tag",
    "group_tag_group",
  ];

  filtersSettings = {
    group_type: {
      label: "Group Types",
      tooltip: {
        url: "https://help.display.church/en/articles/6380138-setup-groups-filtering-filter-selection-pco",
        message: "Pull in groups for these group types",
      },
    },
    location: {
      label: "Locations",
      tooltip: {
        url: "https://help.display.church/en/articles/6380138-setup-groups-filtering-filter-selection-pco",
        message: "Pull in groups for these locations",
      },
    },
    group_status: {
      label: "Group Status",
      tooltip: null,
    },
  };

  filterLabelCTA = FilterLabelCTA();

  /**
   *
   * @param data
   * @returns {{}}
   */
  filterRawDataToStateObject = (data) => {
    const filterObjects = this.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: [] }),
      {}
    );

    data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      filterObjects[filter.kind].push(filter);
    });

    return filterObjects;
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  filterRawToDisplayObject = (data) => {
    return data.map((filter) => {
      filter.value = filter.platform_metadata_id;
      filter.uuid = filter.platform_metadata_id;
      filter.label = this.filterRenderName(filter);
      return filter;
    });
  };

  /**
   *
   * @param data
   * @param showInactive
   * @returns {*}
   */
  filterRenderName = (filter) => {
    const name = filter.data.attributes.name; /* +
      (filter.platform_metadata_id
        ? " [" + filter.platform_metadata_id + "]"
        : "");*/

    return name;
  };

  _filters = [
    {
      field: {
        value: "group_type",
        label: "Group type",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "location",
        label: "Location",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "group_tag",
        label: "Tag",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "group_tag_group",
        label: "Tag group",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
    },
    {
      field: {
        value: "group_status",
        label: "Group status",
      },
      allowedPlans: [PLANS.free, PLANS.starter, PLANS.medium, PLANS.highest],
      options: [
        {
          id: "open",
          platform_metadata_id: "open",
          value: "open",
          label: "Open",
          name: "Open",
          kind: "group_status",
        },
        {
          id: "closed",
          platform_metadata_id: "closed",
          value: "closed",
          label: "Closed",
          name: "Closed",
          kind: "group_status",
        },
        {
          id: "full",
          platform_metadata_id: "full",
          value: "full",
          label: "Full",
          name: "Full",
          kind: "group_status",
        },
        {
          id: "private",
          platform_metadata_id: "private",
          value: "private",
          label: "Private",
          name: "Private",
          kind: "group_status",
        },
      ],
      allowedOperators: ["is", "not"],
      allowedValues: ["open", "closed", "full", "private"],
      fetch: false,
    },
  ];
}

export default PCOStrategy;
