/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/10/2024
 */

import React, { useMemo } from "react";
import InstallAccordion from "./InstallAccordion";
import ClipboardCopyInput from "../../component/ClipboardCopyInput";
import WebsiteMissingWarning from "./WebsiteMissingWarning";
import WebsitesVerifiedInfo from "./WebsiteVerifiedInfo";
import {
  getInstallDivTag,
  getInstallIframeTag,
  installScripTag,
} from "../../templates/WidgetPageTemplate/InstallTab/helpers";

const StandardInstall = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onGoToAddWebsite,
}) => {
  const installDiv = useMemo(
    () => getInstallDivTag(widgetType, uuid),
    [widgetType, uuid]
  );
  const installDivIframe = useMemo(
    () => getInstallIframeTag(widgetType, uuid),
    [widgetType, uuid]
  );
  return (
    <div>
      {/*     <div className={"w-100 d-flex align-items-center flex-column"}>
        <p className={"text-center"}>
          Watch our tutorial video below or <a href={"#"}>Read our Article</a>.
        </p>
        <div>
          <iframe
            width="355"
            height="200"
            src="https://www.youtube.com/embed/7c7Wfz5rMn0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>*/}

      <div className={"my-4"}>
        <WebsiteMissingWarning onAddWebsite={onGoToAddWebsite} />
        <WebsitesVerifiedInfo />
      </div>
      <InstallAccordion
        title={"Recommended installation"}
        defaultExpanded={true}
        expandable={true}
      >
        <ol>
          <li>
            Copy and Paste this Snippet into the{" "}
            <span className={"text-purple"}>&lt;head&gt;</span> of your website:
            <ClipboardCopyInput
              id={"embeddable_script"}
              value={installScripTag}
              placeholder={"Calendar embeddable script"}
            />
          </li>
          <li>
            Then put the following HTML5 object where you want the{" "}
            {widgetTypePrintable} to appear:
            <ClipboardCopyInput
              id={"embeddable_div"}
              value={installDiv}
              placeholder={`Calendar embeddable div`}
            />
          </li>
        </ol>
      </InstallAccordion>
      <InstallAccordion
        title={"Alternative installation"}
        defaultExpanded={false}
        expandable={true}
      >
        <div className={"excerpt"}>
          This option <strong>is not ideal</strong> if you are using multiple{" "}
          {widgetTypePrintable}s per website page. If you wish to try it anyway,
          copy/paste the following code where you would like the{" "}
          {widgetTypePrintable} to appear on your website.
        </div>

        <ClipboardCopyInput
          id={"embeddable_script"}
          value={`${installScripTag} ${installDiv}`}
          placeholder={`${widgetTypePrintable} embeddable script`}
        />
      </InstallAccordion>
      <InstallAccordion
        title={"Experimental installation"}
        defaultExpanded={false}
        expandable={true}
      >
        <div className={"excerpt"}>
          <strong>Fallback method.</strong> In case your website theme is using
          an aggressive stylesheet and the {widgetTypePrintable} does not
          display nicely on your website, copy/paste the following code which
          will embed the {widgetTypePrintable} in the iframe on your website:
        </div>
        <ol>
          <li>
            Copy and Paste this Snippet into the{" "}
            <span className={"text-purple"}>&lt;head&gt;</span> of your website:
            <ClipboardCopyInput
              id={"embeddable_script"}
              value={installScripTag}
              placeholder={`${widgetTypePrintable} embeddable script`}
            />
          </li>
          <li>
            Then put the following HTML5 object where you want the{" "}
            {widgetTypePrintable} to appear:
            <ClipboardCopyInput
              id={"embeddable_div"}
              value={installDivIframe}
              placeholder={`${widgetTypePrintable} embeddable div`}
            />
          </li>
        </ol>
      </InstallAccordion>
    </div>
  );
};

export default StandardInstall;
