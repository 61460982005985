/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/08/2019.
 */

import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";

import DesignTooltip from "./DesignTooltip";
import CheckPropertyDependency from "../../CheckPropertyDependency";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";

const OptionToggle = (props) => {
  const widgetSettingsContext = useContext(WidgetSettingsContext);
  /**
   *
   * @param propertyName
   * @returns {function(*): *}
   */
  const onValueChange = useCallback(
    (propertyName) => (event) => {
      props.updateDesignProp([
        {
          field: propertyName,
          value: event.target.checked ? 1 : 0,
        },
      ]);
    },
    [props.updateDesignProp]
  );

  const { propertyName, settings, propertyDefaultValue } = props;

  const isChecked =
    (widgetSettingsContext[propertyName] || propertyDefaultValue) * 1
      ? true
      : false;

  const tooltip = props.help ? (
    <DesignTooltip help={props.help} icon={"info"} />
  ) : null;

  const inputId = `${props.settingsName}-${propertyName}`;

  return (
    <div>
      <div className="design-element checkbox">
        <label className={"option-label"}>
          <span className={"option-title"}>{props.title}</span>
          {tooltip}
        </label>

        <div className="material-switch pull-right">
          <input
            id={inputId}
            type="checkbox"
            checked={isChecked}
            onChange={onValueChange(propertyName)}
          />
          <label htmlFor={inputId} className="badge-primary" />
        </div>
      </div>
      <CheckPropertyDependency
        settings={settings}
        dependsOnName={props.dependency && props.dependency.field}
        dependsOnValue={props.dependency && props.dependency.value}
        dependsOnDefaultValue={
          props.dependency && props.dependency.defaultValue
        }
        flashMessage={props.dependency && props.dependency.message}
        show={props.showDependencyWarning}
      />
    </div>
  );
};

OptionToggle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  propertyName: PropTypes.string.isRequired,
  propertyDefaultValue: PropTypes.number.isRequired,
  updateDesignProp: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  dependsOnName: PropTypes.string,
  dependsOnValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dependsOnDefaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  flashMessage: PropTypes.string,
  help: PropTypes.object,
  settingsName: PropTypes.string.isRequired,
};

export default OptionToggle;
